'use strict';

angular.module('game').
factory('homeServices', ['$interval', '$timeout', 'contentURL', 'mediaURL', '$q', 'gameSettings',
  function ($interval, $timeout, contentURL, mediaURL, $q, gameSettings) {

    return {

      unlocking: false,

      toggleClassInterval: function (element, classN, timer) {

        var promise = $interval(function () {
          angular.element(".buttonDisabled").removeClass("blink");
          var a = angular.element(element + ":not(.buttonDisabled)");
          if (a.hasClass(classN))
            a.removeClass(classN);
          else
            a.addClass(classN);
        }, timer);

        return promise;

      },

      randomize: function (challengeId, audioPlayer, credits, first) {

        var self = this;

        if ($(".level.available .outside").length != 1 && gameSettings.randomSelection) {
          var timer = 300,
            counter = 15,
            items,
            item,
            itemTemp = "";

          if (audioPlayer) {
            audioPlayer.play();
          }

          var inter = $interval(function () {

            if (angular.element(document.querySelectorAll('.outside.active'))[0] != null) {
              var element = angular.element(document.querySelector('.outside.active'))[0];
              element.className = "outside";
            }

            items = $(".level.available .outside");
            itemTemp = items[0];

            if (items.length > 1) {
              while (item == itemTemp) {
                itemTemp = Utils.rand(items);
              }
            }

            item = itemTemp;
            item.className = item.className + " active";
            counter = counter - 1;

            if (counter == 0) {
              self.finalSelection(challengeId, credits, first);

              if (audioPlayer) {
                audioPlayer.pause();
              }
            }

          }, timer, 15);
        } else {
          self.finalSelection(challengeId, credits, first);
        }

        return inter;
      },

      /* FUNCTION TO SELECT AND UNLOCK THE LAST CHALLENGE */
      finalSelection: function (challengeId, credits, first) {

        var self = this;
        var item = angular.element(document.querySelector("#c" + challengeId).parentNode);
        item.addClass("chapters unlocked");
        var $challenge = angular.element("#c" + challengeId);
        $challenge.removeClass('locked');

        if (first) {
          $challenge.css("border", "2px solid white");
        }

        var boundings = {};
        boundings.left = $challenge.offset().left;
        boundings.top = $challenge.offset().top - 170;

        var $down = angular.element(".down");
        $down.css("top", boundings.top);
        $down.css("left", boundings.left);
        $down.css("display", "block");
        angular.element(".unlockButton").css("z-index", 0); /* IF FIRST TIME*/

        $challenge.css("z-index", 4); /* IF FIRST TIME*/

        if (angular.element(".level.available .outside").length != 1) {
          angular.element('.outside.active').removeClass("active");
          var toggleOutside = true;

          $interval(function () {
            toggleOutside = !toggleOutside;
            if (toggleOutside) {
              angular.element('.unlocked .outside').addClass("outside active");
            } else {
              angular.element('.unlocked .outside').removeClass("unlocked");
            }
          }, 100, 10);

          var timerSelection = $timeout(function () {
            item.removeClass("unlocked");
            angular.element('.outside.active').removeClass("active");
            $timeout.cancel(timerSelection);
          }, 1500);

          if (item.find("a.locked")[0] == null) {
            $(item.parent()).removeClass("available");
          }

        }

        self.unlocking = false;
        if (credits > 0) /* CHECKME */
          angular.element(".unlockButton").removeClass("buttonDisabled")
      },

      unlockAudioStart: function () {
        /* INITIALIZE SOUND EFFECT PLAYER */
        var audioPlayer = jwplayer(angular.element("#soundEffect")[0]).setup({
          modes: [{
            "type": "html5"
          }, {
            "type": "flash",
            "src": contentURL + 'player/' + gameSettings.settings.videoPlayer.location +  '/jwplayer.flash.swf',
          }],
          'primary': 'html5',
          file: mediaURL + "sounds/sound-menu-luck-01.mp3",
          autoplay: false,
          volume: 100,
          width: '1px',
          height: '1px',
          events: {
            onComplete: function (evt) {
              this.seek(0);
            }
          }
        });
        return audioPlayer;
      }
    }
  }
])
