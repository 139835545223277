'use strict';

/* Services */

angular.module('game').value('version', '0.1')
  .factory('authHttpResponseInterceptor', ['$q', '$location', '$window', '$rootScope',
    function ($q, $location, $window, $rootScope) {
      return {
        response: function (response) {
          return response || $q.when(response);
        },
        responseError: function (rejection) {
          var config = rejection.config;
          if (config.handleErrors && config.handleErrors[rejection.status]) {
            // Handle error
          } else {
            switch (rejection.status) {
              case 403: // Not authorized
                // @todo this should be addressed later on
                // $window.location.href = rejection.headers('Location');
                $location.path("/");
                break;
              case 409: // Conflict error
                $rootScope.showError('front.challenge.friendError');
                break;
              case 0: // Timeout error
                $rootScope.showError('timeoutError');
                break;
              default:
                $rootScope.showError();
                $location.path("/");
                break;
            }
          }
          return $q.reject(rejection);
        }
      }
    }
  ])
  .factory('tenantHeaderInterceptor', ['tenant', function (tenant) {
    return {
      request: function (config) {
        config.headers['X-Tenant-Id'] = tenant;
        return config;
      }
    };
  }])
  .config(['$httpProvider', function ($httpProvider) {
    //Http Intercepetor to check auth failures for xhr requests
    $httpProvider.interceptors.push('authHttpResponseInterceptor');
    $httpProvider.interceptors.push('tenantHeaderInterceptor');
  }]);
