'use strict';

/* Notifications Controller */
angular.module('game')
.directive('notifications', function() {
  return {
    restrict: 'A',
    templateUrl: 'app/partials/sidebar/ranking-block/notifications/notifications.html',
    scope: {},
    controller: function ($scope, $timeout, $rootScope, apiCall, contentURL, mediaURL, rankingService, i18nService) {

      i18nService.getLocale().then(function (locale) {
        $scope.locale = locale;
      });

      $scope.bell = mediaURL + 'images/common/icons/bell.png';
      $scope.mediaURL = mediaURL;
      $scope.contentURL = contentURL;

      $scope.notifications = [];

      rankingService.getFriendshipRequests().then(function (data) {
        if (data[0] != null) {
          $scope.notifications = data;
        }

        $scope.acceptRequest = function (id) {
          angular.element("#" + id).remove();
          apiCall.post('api/1/friendship/accept', "" + id).then(function() {
            $rootScope.$broadcast('refreshFriends');
          });

          // if there're no other notification to attend, then close the popup
          if (angular.element(".enqueued-challenges li").length == 0) {
            $scope.notificationsPopupAPI.close();
          }
        };

        $scope.denyRequest = function (id) {
          apiCall.post('api/1/friendship/reject', "" + id);
          angular.element("#" + id).remove();

          // if there're no other notification to attend, then close the popup
          if (angular.element(".enqueued-challenges li").length == 0) {
            $scope.notificationsPopupAPI.close();
          }
        };

      });

      if ($rootScope.errorPage) {

      }

      $scope.notificationsPopupAPI = null;

      // Get the popup API
      $scope.$watch('notificationsPopupAPI', function() {
        if ($scope.notificationsPopupAPI) {
          $scope.$parent[$scope.popupAttr] = $scope.notificationsPopupAPI.addClass('notifications-container');
        }
      });

    },
    link: function(scope, element, attrs) {

      scope.element = angular.element(element);
      var helpAttr = attrs['help'];

      // This is the attribute that will expose the API to the wrapper controller
      if (!helpAttr) {
        helpAttr = 'notificationsPopupAPI';
      }
      scope.popupAttr = helpAttr;

    }
  }
});
