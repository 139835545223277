'use strict';

/* Services */

angular.module('game').value('version', '0.1')
  .factory('keylogger', ['$timeout', function ($timeout) {

    var isLogging = false;
    var matches = {};
    var enteredText = '';
    var timer;

    var checkMatch = function () {
      if (matches[enteredText] !== undefined && typeof (matches[enteredText]) == 'function') {
        matches[enteredText].call();
      }
    };

    var keypress = function (e) {
      enteredText = enteredText + String.fromCharCode(e.keyCode || e.charCode);
      checkMatch();

      if (timer) {
        $timeout.cancel(timer);
      }

      timer = $timeout(function () {
        enteredText = '';
      }, 1000);
    };

    var logKeys = function () {
      isLogging = true;
      $(document).on('keypress', keypress);
    };

    return {
      log: function (text, func) {
        if (!isLogging) {
          logKeys();
        }
        matches[text] = func;
      },

      removeLog: function (text) {
        delete matches[text];
      },

      stop: function () {
        matches = {};
        isLogging = false;
        $(document).off('keypress', keypress);
      }
    };

  }])
