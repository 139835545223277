'use strict';

/* Ranking Services */

angular.module('game')
.factory('rankingService', ['apiCall', '$rootScope', '$timeout', 'contentURL', 'i18nService',
  function (apiCall, $rootScope, $timeout, contentURL, i18nService) {

    return {

      requestPendingFriends: function () {
        return apiCall.get("api/1/friendship/pending/response").then(function (response) {
          return response.data;
        });
      },

      requestFriends: function () {
        return apiCall.get("api/1/friendship").then(function (response) {
          return response.data;
        });
      },

      requestUsersGlobal: function () {
        return apiCall.get("api/1/ranking/global").then(function (response) {
          return response.data;
        });
      },

      requestUsersCustomRanking: function () {
        return apiCall.get("api/1/ranking/crew").then(function (response) {
          return response.data;
        });
      },

      /**
       * Returns the pending notifications
       * @returns {*}
       */
      getFriendshipRequests: function () {
        return apiCall.get('api/1/friendship/pending/request').then(function (response) {
          return response.data;
        });
      },

      /**
       * Deletes a friend relationship
       * @param id
       */
      deleteFriend: function (id) {
        apiCall.post('api/1/friendship/delete', "" + id);
        // Refresh friends
        $rootScope.$broadcast('refreshFriends');
      },

      sendChallenge: function (id, success, fail) {
        return apiCall.post('api/1/friendship/request', "" + id).then(success).catch(fail);
      },

    };
  }
])
;
