'use strict';

/* Template Page */

angular.module('game')
.directive('templatePage', function() {
  return {
    restrict: 'A',
    transclude: true,
    templateUrl: 'app/partials/template-page/template-page.html',
  };
});
