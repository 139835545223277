'use strict';

/* Template Page */

angular.module('game')
.directive('popup', function() {
  return {
    restrict: 'A',
    templateUrl: 'app/partials/popup/popup.html',
    transclude: true,
    scope: {},
    controller: function($scope, contentURL) {

      var controller = this;

      $scope.contentURL = contentURL;

      $scope.popupClasses = '';
      $scope.alertHeight = '100%';

      $scope.$watch('alertHeight', function() {
        $scope.top = 'calc(10% - (330 - ' + $scope.alertHeight + '))';
      });

      $scope.popupStatus = 'hidden';
      controller.callbacksOnClose = [];

      this.popupAPI = {
        addClass: function(clazz) {
          $scope.popupClasses += ' ' + clazz;
          return this;
        },
        setHeight: function(height) {
          $scope.alertHeight = height;
          return this;
        },
        open: function() {
          $scope.popupStatus = '';
          return this;
        },
        close: function() {
          $scope.popupStatus = 'hidden';
          $scope.closing();
          return this;
        },
        addCallbackOnClose: function(callback) {
          controller.callbacksOnClose.push(callback);
          return this;
        }
      };

      /**
       * Runs closing callbacks
       */
      $scope.closing = function() {
        $.each(controller.callbacksOnClose, function() {
          this();
        });
      };

      $scope.close = this.popupAPI.close;
    },
    link: function(scope, element, attrs, ctrl) {

      if (attrs.popup !== undefined) {
        scope.$parent[attrs.popup] = ctrl.popupAPI;
      }

      angular.element(element).appendTo('body');

    }
  };
});
