'use strict';

/* Level Switcher */

angular.module('game')
.directive('levelSwitcher', ['$rootScope', 'apiCall', 'i18nService', '$timeout', function ($rootScope, apiCall, i18nService, $timeout) {
  return {
    restrict: 'A',
    scope: {
    },
    templateUrl: 'app/partials/header/level-switcher/level-switcher.html',
    link: function($scope) {

      var loaded = false;
      var timeout;
      $scope.$on('$destroy', function() {
        $timeout.cancel(timeout);
      });

      var action = {shouldDisplay: false};
      $rootScope.$broadcast('levelSwitcherListener', action);
      $scope.shouldDisplay = action.shouldDisplay;

      $scope.successChallenges = $scope.totalChallenges = 0;

      i18nService.getLocale().then(function(decalogue) {
        $scope.completedLevels = decalogue['front.levelSwitcher.completed'];
      });

      var getSeason = function() {
        if (!loaded) {
          loaded = true;
          apiCall.get("api/1/common/season").then(function (response) {
            var seasonData = response.data;
            $scope.numberOfSeasons = seasonData.numberOfSeasons;

            if ($scope.numberOfSeasons > 1) {
              angular.element('body').attr('level-switcher', 'multiple-seasons');
            }

            $scope.seasonsCache = seasonData.seasons;
            $scope.currentSeason = seasonData.currentLevel;
            $scope.currentSeasonName = seasonData.seasons[$scope.currentSeason].nameI18n;

            $scope.successChallenges = seasonData.seasons[seasonData.currentLevel].successChallenges;
            $scope.totalChallenges = seasonData.seasons[seasonData.currentLevel].totalChallenges;
          });
        }

      };

      /**
       * FUNCTION TO BE CALLED WHEN SEASON CHANGES. REFRESH THE GRID AND USER STATUS CORRESPONDING TO THE NEW LEVEL
       * @param sum
       */
      $scope.changeSeason = function (sum) {

        /* CHECK IF THERE ARE NEXT OR PREVIOUS SEASONS */
        var newSeason = parseInt($scope.currentSeason) + sum;
        if (newSeason > 0 && newSeason <= $scope.numberOfSeasons) {

          $scope.currentSeason = newSeason;
          var self = this;
          apiCall.get("api/1/grid/" + $scope.seasonsCache[newSeason].id).then(function (response) {
            var data = response.data;
            $scope.successChallenges = data.status.successChallenges;
            $scope.totalChallenges = data.status.totalChallenges;

            $rootScope.$broadcast('seasonChanged', newSeason, data);
            self.timeout = $timeout(function () {
              var season = $('.season');
              season.animateRotate(90, 0, {cssTransform: 'rotateX'});
              $scope.status = data.status;
              $scope.currentSeasonName = $scope.seasonsCache[newSeason].nameI18n;
              season.animateRotate(0, 90, {cssTransform: 'rotateX'});
            }, 100)
          })
        }
      };

      $scope.$watch('shouldDisplay', function() {
        getSeason();
      });
      getSeason();
    }
  };
}])
;
