'use strict';

var Utils;
angular.module('game').config(function () {

  Utils = {

    /**
     * Dummy function to avoid behavior under certain circunstances
     */
    noop: function(){
    },

    /**
     * Gets the real width of a jQuery element (with decimals)
     *
     * jQuery.width() rounds up the width of an element,
     * this gets the real value
     *
     * @param $element
     * @returns {*}
     */
    realWidth: function($element) {
      return $element[0].getBoundingClientRect().width;
    },

    /**
     * Gets a random value from a list
     * @param values
     * @returns {*}
     */
    rand: function (values) {
      return values[Math.floor(Math.random() * values.length)];
    },

    /**
     * Shuffles an array randomly
     *
     * @param array
     * @returns {*}
     */
    shuffle: function (array) {
      var currentIndex = array.length,
        temporaryValue, randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },

    /**
     * Determines if the browser has support for canvas elements
     *
     * @returns {boolean}
     */
    canvasSupported: function () {
      var elem = document.createElement('canvas');
      return !!(elem.getContext && elem.getContext('2d'));
    },

    /**
     * Detect if browser is IE9
     *
     * @returns {boolean}
     */
    isIE9: function() {
      return jQuery('html.ie9').length > 0;
    },

    /**
     * Detects IE
     * returns version of IE or false, if browser is not Internet Explorer
     */
    isIE: function() {
      var ua = window.navigator.userAgent;

      var msie = ua.indexOf('MSIE ');
      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      var trident = ua.indexOf('Trident/');
      if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      var edge = ua.indexOf('Edge/');
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      }

      // other browser
      return false;
    },

    /**
     * Detects Safari
     * returns if browser is Safari only
     * http://stackoverflow.com/questions/5899783/detect-safari-chrome-ie-firefox-opera-with-user-agent
     */
    isSafari: function() {
      var ua = window.navigator.userAgent;

      var safari = ua.indexOf('Safari');
      if (safari != -1) {
        return ua.indexOf('Chrome') == -1;
      }

      // other browser
      return false;
    },
    /**
     * Detects chrome
     * returns if browser is Chrome only
     * @returns {boolean}
     */
    isChrome: function() {
      return !!window.chrome && !this.isOpera();
    },
    /**
     * Detects Opera
     * returns if browser is Opera only
     * @returns {boolean}
     */
    isOpera: function() {
      return !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    },

    /**
     * Detects if the user is browsing with a mobile (not tablet)
     *
     * @returns {boolean}
     */
    isMobile: function () {
      var md = new MobileDetect(window.navigator.userAgent);
      return md.phone();
    },

    /**
     * Detects if the user is browsing with a tablet
     *
     * @returns {boolean}
     */
    isTablet: function () {
      var md = new MobileDetect(window.navigator.userAgent);
      return md.tablet();
    },

    /**
     * Detects if the user is browsing through a mobile/tablet device
     *
     * @returns {*|boolean}
     */
    isMobileOrTablet: function() {
      return this.isMobile() || this.isTablet();
    },

    /**
     * Returns rgb code from percentage value
     * @param value
     * @param darker
     * @returns {string}
     */
    toRGB: function (value, darker) {
      if (darker == undefined) {
        darker = false;
      }

      var r, g, percentage = parseInt(value);
      if (percentage > 50) {
        var complement = (100 - (percentage));
        if (darker) {
          complement = (100 - (percentage / 2));
        }

        r = parseInt((complement * 2) * 255 / 100);
        g = 255 - percentage;
      } else {
        if (darker) {
          percentage = percentage / 2;
        }

        r = 255;
        g = parseInt((percentage * 2) * 255 / 100);
      }
      return 'rgb(' + r + ',' + g + ',' + '0)';
    },

    /**
     * Applies an opacity/luminosity to an hex
     *
     * @param hex
     * @param lum
     * @returns {string}
     */
    colorLuminance: function(hex, lum) {

      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, '');
      if (hex.length < 6) {
        hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      var rgb = "#", c, i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i*2,2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00"+c).substr(c.length);
      }

      return rgb;
    },

    getKeys: function (obj) {
      var keys = [];
      for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
          keys.push(i);
        }
      }
      return keys;
    },

    /**
     * Prepares an array of elements from min to max with step
     *
     * @param min
     * @param max
     * @param step
     * @returns {Array}
     */
    range: function (min, max, step) {
      step = (step === undefined) ? 1 : step;
      var input = [];
      for (var i = min; i <= max; i += step) input.push(i);
      return input;
    },

    /**
     * Complements the collapsible component, toggling the collapsed class
     *
     * This function should be run only once, as it's generic for all components
     */
    collapsible: function() {
      angular.element('body:not(".collapsing-processed")')
      .addClass('collapsing-processed')
      .on('click', function(e) {
        var $element = $(e.target);
        var $block = $element.parent();
        if ($block.hasClass('collapsible') && $element.hasClass('title')) {
          $block.toggleClass('collapsed');
        }
      });
    }
  };

  /* CHANGE LEVEL ANIMATION */
  $.fn.animateRotate = function (angle, startAngle, config) {

    var base = {
      duration: '',
      easing: '',
      complete: $.noop,
      cssTransform: 'rotateY',
    };

    config = $.extend(base, config);

    return this.each(function () {
      var $elem = $(this);
      $({
        deg: startAngle
      }).animate({
        deg: angle
      }, {
        duration: config.duration,
        easing: config.easing,
        step: function (now) {
          $elem.css({
            transform: config.cssTransform + '(' + now + 'deg)'
          });
        },
        complete: config.complete
      });
    });
  };
});
