'use strict';

/* Rewards services */

angular.module('game').factory('rewardsService', ['gameSettings', 'apiCall', function(gameSettings, apiCall) {
  return {

    /**
     * Returns whether the Rewards system is active for the current tenant
     */
    rewardsActive: function() {
      return gameSettings.rewards.active;
    },

    /**
     * Gets the rewards of the user
     * @returns {*}
     */
    getRewardsStatus: function () {
      var promise = apiCall.get("api/1/reward/status").then(function (response) {
        return response.data;
      });

      return promise;
    },

  }
}]);
