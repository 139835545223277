'use strict';

/* Localization */

angular.module('game')
.run(function(contentURL, gameSettings) {
  if (gameSettings.settings.videoPlayer.name == 'jwplayer') {
    // JWPlayer default config
    jwplayer.defaults = {
      "abouttext": gameSettings.settings.site.name,
      'aboutlink': '/',
      "autostart": false,
      "aspectratio": "16:9",
      "controls": false,
      "displaydescription": false,
      "displaytitle": false,
      "base": '.',
      'flashplayer': contentURL + 'player/' + gameSettings.settings.videoPlayer.location +  '/jwplayer.flash.swf',
      "height": 260,
      "key": "wqpqzhbRNfk7UXugViKSFlN8RZUow2xyn7U0BA==",
      "mute": gameSettings.video.mute,
      "ph": 1,
      "pid": "QOUCEdTr",
      "plugins": {
        // "https://assets-jpcust.jwpsrv.com/player/6/6124956/ping.js": {
        //   "pixel": "https://content.jwplatform.com/ping.gif"
        // }
      },
      "preload": "auto",
      "primary": "html5",
      "repeat": false,
      "stagevideo": false,
      "stretching": "uniform",
      "width": "100%",
      "analytics": {
        "cookies": false,
        "enabled": false
      },
      // 'ga': {},
      // 'logo': {
      //   'hide': true
      // },
    };
  }
})
.factory('playerService', ['gameSettings', 'logService', function (gameSettings, logService) {

  // @todo: loading the video player library should be done on compiling time, currently this is done
  // @todo: explicity in index.html

  // Factory API interface
  var factoryAPI = {
    create: function(id) {
    },
  };

  var instanceAPI = {
    setup: function() {
      throw new Error('Method not implemented');
    },
    play: function() {
      throw new Error('Method not implemented');
    },
    pause: function() {
      throw new Error('Method not implemented');
    },
    stop: function() {
      throw new Error('Method not implemented');
    },
    remove: function() {
      throw new Error('Method not implemented');
    },
    setVolume: function(volume) {
      throw new Error('Method not implemented');
    },
    getVolume: function() {
      throw new Error('Method not implemented');
    },
    getBuffer: function() {
      throw new Error('Method not implemented');
    },
    getPosition: function() {
      throw new Error('Method not implemented');
    },
    seek: function(position) {
      throw new Error('Method not implemented');
    },
    onComplete: function(callback) {
      throw new Error('Method not implemented');
    },
    onReady: function(callback) {
      throw new Error('Method not implemented');
    },
    onPause: function(callback) {
      throw new Error('Method not implemented');
    },
    onTime: function(callback) {
      throw new Error('Method not implemented');
    },
    onDisplayClick: function(callback) {
      throw new Error('Method not implemented');
    },
    attachToScope: function($scope){
      var self = this;

      $scope.$on('$destroy', function() {
        self.stop();
        self.remove();
        logService.debug('Player stopped and removed: Scope destroyed');
      });

      return this;
    },
    resize: function() {
      // This is not implemented for real!
      throw new Error('Method not implemented');
    },
    isPlaying: function() {
      throw new Error('Method not implemented');
    },
    isIdle: function() {
      throw new Error('Method not implemented');
    },
    isPaused: function() {
      throw new Error('Method not implemented');
    },
    isBuffering: function() {
      throw new Error('Method not implemented');
    },
    getElement: function() {
      throw new Error('Method not implemented');
    }
  };

  var setup = {
    createAPIJWPlayer: function(factoryAPI, instanceAPI) {

      var instanceAPIJWPlayer = (function() {
        // Implementation for instance JWPlayer
        var instanceJWPlayer = function(settings, id) {
          // Constructor
          this.location = settings.location; // location of the player

          this.id = id;

          // Private members
          var instance = jwplayer(id);

          // Private methods
          var getInstance = function() {
            return instance;
          };

          // Public methods
          this.setup = function(config) {
            instance && instance.setup(config);
            return this;
          };

          this.play = function() {
            var state = getState();
            if (state == 'playing') {
             return this;
            }
            instance && instance.play();
            logService.debug('Player: Play');
            return this;
          };

          this.pause = function() {
            instance && instance.pause();
            logService.debug('Player: Pause');
            return this;
          };

          this.stop = function() {
            instance && instance.stop();
            logService.debug('Player: Stop');
            return this;
          };

          this.remove = function() {
            instance && instance.remove();
            logService.debug('Player: remove');
            return this;
          };

          this.setVolume = function(volume) {
            instance && instance.setVolume(volume);
            logService.debug('Player: Set volume to: ' + volume);
            return this;
          };

          this.getVolume = function() {
            if (instance) {
              return instance.getVolume();
            }
            return false;
          };

          this.getBuffer = function() {
            if (instance) {
              return instance.getBuffer();
            }
            return false;
          };

          this.getPosition = function() {
            if (instance) {
              return instance.getPosition();
            }
            return false;
          };

          this.seek = function(position) {
            instance && instance.seek(position);
            logService.debug('Player: Seek position to: ' + position);
            return this;
          };

          this.onComplete = function (callback) {
            instance && instance.on('complete', callback);
            return this;
          };

          this.onReady = function(callback) {
            instance && instance.on('ready', callback);
            return this;
          };

          this.onPause = function(callback) {
            instance && instance.on('pause', callback);
            return this;
          };

          this.onTime = function(callback) {
            instance && instance.on('time', callback);
            return this;
          };

          this.onDisplayClick = function(callback) {
            instance && instance.on('displayClick', callback);
            return this;
          };

          this.isPlaying = function() {
            var state = getState();
            return state == 'playing';
          };

          this.isIdle = function() {
            var state = getState();
            return state == 'idle';
          };

          this.isPaused = function() {
            var state = getState();
            return state == 'paused';
          };

          this.isBuffering = function() {
            var state = getState();
            return state == 'buffering';
          };

          this.getElement = function() {
            return angular.element('#' + id);
          };

          this.setCaptions = function(styles) {
            instance && instance.setCaptions(styles);
            return this;
          };

          this.setCurrentCaptions = function(index) {
            instance && instance.setCurrentCaptions(index);
            return this;
          };

          this.getCurrentCaptions = function() {
            if (instance){
              return instance.getCurrentCaptions();
            }
            return this;
          };

          this.getCaptionsList = function() {
            if (instance) {
              return instance.getCaptionsList();
            }
            return false;
          };

          var getState = this.getState = function() {
            if (instance) {
              return instance.getState();
            }
            return false;
          }

        };

        // Make sure the implementation follows the API
        instanceJWPlayer.prototype = instanceAPI;

        return instanceJWPlayer;

      })();

      return (function() {

        // Implementation for JWPlayer
        var jwPlayerFactory = function(settings) {

          // Public methods
          this.create = function(id) {
            return new instanceAPIJWPlayer(settings, id);
          };

          this.remove = function(id) {
            var instance = new instanceAPIJWPlayer(settings, id);
            instance.stop();
            instance.remove();
          }
        };

        // Make sure the implementation follows the API
        jwPlayerFactory.prototype = factoryAPI;

        return jwPlayerFactory;
      })();

    }
  };

  var APIJWPlayerFactory = setup.createAPIJWPlayer(factoryAPI, instanceAPI);

  var apiMapping = {
    'jwplayer': APIJWPlayerFactory
  };

  // factory
  var factory = function(videoPlayerName, settings) {
    return new apiMapping[videoPlayerName](settings);
  };

  return factory(gameSettings.settings.videoPlayer.name, gameSettings.settings.videoPlayer.settings);
}]);
