'use strict';

/* Easter Egg Directive */

angular.module('game')
.directive('easterEgg', function() {
  return {
    restrict: 'A',
    templateUrl: 'app/partials/easter-egg/easter-egg.html',
    scope: {},
    controller: function($scope, i18nService, keylogger, $q, contentURL, mediaURL, playerService) {

      var controller = this;

      this.eggs = null;

      $scope.element = null;

      this.videoPlayer = null;

      /**
       * FUNCTION TO SHOW HIDDEN EASTER EGG
       */
      this.activateEgg = function () {

        controller.eggs.forEach(function (egg) {
          keylogger.log(egg, function () {
            $scope.easterEggAPI
              .setHeight(550)
              .addClass('easter-egg-wrapper')
              .open();

            controller.easterEggPlayer(egg);
          });
        });
      };

      /**
       * SHOW AND PLAY THE EASTEREGG
       */
      this.easterEggPlayer = function (text) {

        function isImage(src) {
          var deferred = $q.defer();
          var image = new Image();
          image.onerror = function () {
            videoCallback();
          };
          image.onload = function () {
            $scope.element.find(".easter-egg").append("<img height='500' src='" + contentURL + "eggs/" + src + ".jpg' />");
          };
          image.src = contentURL + "eggs/" + src + ".jpg";
          return deferred.promise;
        }

        function videoCallback() {
          $scope.element.find(".text").html(text);
          controller.videoPlayer = playerService.create('easterEgg')
            .setup({
              file: mediaURL + "videos/eggs/" + text + ".mp4",
              mediaid: text,
              autostart: true,
              volume: 100,
              width: '90%',
              height: '450px',
              mute: false,
              events: {
                onComplete: function () {
                  $scope.element.find(".easter-egg-wrapper").style("opacity", 0.8);
                }
              }
            })
            .attachToScope($scope);

          $scope.element.find(".easter-egg-wrapper").css("opacity", 1);
        }

        isImage(text);

        $scope.easterEggAPI.addCallbackOnClose(function() {
          if (controller.videoPlayer.getPosition() != null) {
            controller.videoPlayer.stop();
            controller.videoPlayer.remove();
          }
        }.bind($scope));
      };

      // Load eggs
      i18nService.getLocale().then(function (decalogue) {
        if (decalogue['front.terms.eggs'] !== undefined) {
          controller.eggs = decalogue['front.terms.eggs'].split(",");
          controller.activateEgg();

        }
      });

      // Get the popup API
      $scope.$watch('easterEggAPI', function() {
        if ($scope.easterEggAPI) {
          $scope.$parent[$scope.popupAttr] = $scope.easterEggAPI.addClass('easter-egg');
        }
      });

    },
    link: function(scope, element, attrs) {

      scope.element = angular.element(element);
      var helpAttr = attrs['help'];

      // This is the attribute that will expose the API to the wrapper controller
      if (!helpAttr) {
        helpAttr = 'easterEggAPI';
      }
      scope.popupAttr = helpAttr;

    }
  };
});
