'use strict';

/* Video Controller */
angular.module('game').controller('introCtrl',
  ['gameSettings', '$scope', 'introServices',
  function (gameSettings, $scope, introServices) {

    $scope.config = gameSettings;

    $scope.autostartVideo = gameSettings.video.autostart && !Utils.isMobileOrTablet() && !Utils.isSafari() && !Utils.isChrome();

    $scope.$emit("pageChange", {page: "intro"});

  }
]);
