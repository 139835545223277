'use strict';

/* Services */

angular.module('game').value('version', '0.1')
  .factory('gameConstants', ['petitionURL', 'contentURL', 'mediaURL', 'staticURL', 'config', 'modules', 'tenant',
    function (petitionURL, contentURL, mediaURL, staticURL, config, modules, tenant) {
      return {
        petitionURL: petitionURL,
        contentURL: contentURL,
        mediaURL: mediaURL,
        staticURL: staticURL,
        config: config,
        modules: modules,
        tenant: tenant
      };
    }
  ])
;
