'use strict';

angular.module('game').controller('loginCtrl', ['$scope', 'i18nService', 'globalService', '$location', '$routeParams', '$timeout', '$interval', 'gameSettings', 'config', 'tenant', 'localStorageService', '$route',
  function ($scope, i18nService, globalService, $location, $routeParams, $timeout, $interval, gameSettings, config, tenant, localStorageService, $route) {

    var loggingIn = false;

    var init = function() {
      i18nService.getLocale().then(function(locale) {
        $scope.locale = locale;
      });
      /* --- Login user section --- */
      $scope.loginModel = {
        username: null,
        password: null
      };
      $scope.errorLogin = null;
      $scope.loggedIn = false;

      /* Recovering password */
      $scope.recoverModel = {
        username: null
      };

      $scope.errorRecover = null;
      $scope.passwordRecoveryMode = false;
      $scope.isPasswordRecovered = false;

      /* Setting new password */
      $scope.newPasswordModel = {
        password1: null,
        password2: null
      };
      $scope.newPasswordMode = false;
      $scope.errorNewPassword = null;
      $scope.newPasswordSet = false;
      $scope.token = null;

      $scope.checkingPasswordMatch = false;
      $scope.checkingPasswordMatchTimeout = false;

      // Reset password endpoint loaded with token
      if ($routeParams['reset'] !== undefined && $routeParams['token'] !== undefined) {
        // In reset password mode
        $scope.newPasswordToken = $routeParams['token'];
        $scope.newPasswordMode = true;


        // @todo
        // 1- Validate token, if not valid then redirect to home

      } else if ($routeParams['reset'] !== undefined) {
        // Go to the password reset screen
        $scope.forgotPassword();
      }


      /* --- Sign up user section --- */
      $scope.signUpEnabled = gameSettings.intro.signUpEnabled;
      $scope.signUpMessages = [];
      $scope.signUpSuccess = false;

      // Account activation
      $scope.accountActivationMode = false;
      $scope.activationSuccess = false;
      $scope.activationMessage = null;

      $scope.signUpModel = {
        nickname: null,
        surname: null,
        email: null,
        employeeId: null,
        password1: null,
        password2: null
      };

      // Sign up activation endpoint loaded with token
      if ($routeParams['activation'] !== undefined && $routeParams['activationToken'] !== undefined) {

        // todo Validate token with regex, if not valid then redirect to home
        // do the API request to activate account
        globalService.activateAccount($routeParams['activationToken']).then(function() {
            // Success
            $scope.activationMessage = $scope.locale['front.signup.activation.success'];
            $scope.activationSuccess = true;
            // Show account activation with messages
            $scope.accountActivationMode = true;
          },
          function(reason) {
            // Fail
            if (reason.status === 400 || reason.status === 500) {
              // Failed for one of the following reasons:
              // Invalid token/token expired
              $scope.activationMessage = $scope.locale['front.signup.activation.fail'];
              if (reason.data && reason.data !== "") {
                $scope.activationMessage += ", " + reason.data;
              }
              $scope.highlightError();
              $scope.activationSuccess = false;
              // Show account activation with messages
              $scope.accountActivationMode = true;
            }
          });

      }


      $scope.showForm = true;
      if (config.login && config.login.type !== 'form') {
        $scope.showForm = false;
        $scope.loginURL = config.login.url || '#';
      } else if (config.login && config.login.type === 'form' && config.login.url) {
        $scope.samlURL = config.login.url;
      }

      globalService.loggedIn().then(function (data) {
        if (data) {
          $scope.loggedIn = true;

          var localStorageRefererUrlKey = "login_referer_url_" + tenant;
          var refererUrl = localStorageService.get(localStorageRefererUrlKey);
          if (refererUrl) {
            localStorageService.remove(localStorageRefererUrlKey);
            $location.path(refererUrl);
          }

          if ($scope.newPasswordMode) {
            // IF the user is logged in, he/she can't be recovering a password
            $scope.goHome();
          }
        }
      });
    };

    /***** Controller Methods *******/

    /**
     * Asks the login service to login the user
     * @param e
     * @returns {boolean}
     */
    $scope.login = function (e) {
      $scope.errorLogin = null;

      if (!loggingIn) {
        loggingIn = true;
      }

      globalService.login($scope.loginModel.username, $scope.loginModel.password).then(function (response) {
        // Success
        var challengeId = response.data.currentChallengeId;
        var firstAccess = response.data.firstAccess;
        var refererUrl = localStorageService.get("login_referer_url_" + tenant);
        if (refererUrl) {
          localStorageService.remove("login_referer_url_" + tenant);
          $location.path(refererUrl);
        } else if(gameSettings.login.redirectToChallenge && firstAccess && challengeId) {
          $location.path("/challenge/" + challengeId);
        } else {
          $location.path("/home");
        }

        loggingIn = false;

      }, function (reason) {

        if (reason.status == 401) {
          // Failed
          $scope.errorLogin = $scope.locale['front.login.badCredentials'];
        }

        loggingIn = false;
      });

      return false; // do not make the form redirection
    };

    /**
     * Asks the login service to logout the user
     */
    $scope.logout = function() {
      globalService.logout();
    };

    /**
     * Recover user passwords
     * @param $e
     */
    $scope.recoverPassword = function($e) {
      $scope.errorRecover = null;

      globalService.recoverPassword($scope.recoverModel.username).then(function () {
        // Success
        $scope.isPasswordRecovered = true;

      }, function (reason) {

        $scope.isPasswordRecovered = false;

        if (reason.status == 400) {
          // Failed
          $scope.errorRecover = $scope.locale['front.login.userNotFound'];
        }

      });

      return false; // don't make a form redirection
    };

    /**
     * Informs the user if the new passwords match or not in realtime
     */
    $scope.validateNewPasswordForm = function($e) {
      $scope.errorNewPassword = null;
      if ($scope.checkingPasswordMatch) {
        $scope.checkingPasswordMatch = false;
        if ($scope.newPasswordModel.password1 !== $scope.newPasswordModel.password2) {
          // Passwords don't match
          $scope.errorNewPassword = $scope.locale['front.login.error.passwordNotMatching'];
          $scope.highlightError();
        } else {
          if (!$scope.validatePassword($scope.newPasswordModel.password1)) {
            // Password invalid
            $scope.errorNewPassword = $scope.locale['front.login.error.invalidPassword'];
            $scope.highlightError();
            return;
          }
        }
      } else {
        $timeout.cancel($scope.checkingPasswordMatchTimeout);
        $scope.checkingPasswordMatchTimeout = $timeout(function() {
          $scope.checkingPasswordMatch = true;
          $scope.validateNewPasswordForm();
        }, 1000);
      }

      return true;
    };

    /**
     * Checks if the password is strong enough
     *
     * @returns {boolean}
     */
    $scope.validatePassword = function(password) {
      var pattern = new RegExp(/(?=^.{8,100}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/);
      return pattern.test(password);
    };

    /**
     * Checks if the nickname is valid with same regex than backend
     *
     * @returns {boolean}
     */
    $scope.validateNickname = function(nickname) {
      var pattern = new RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'#-]{3,50}$/);
      return pattern.test(nickname);
    };


    /**
     * Checks if the surname is valid with same regex than backend
     *
     * @returns {boolean}
     */
    $scope.validateSurname = function(surname) {
      var pattern = new RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'#-]{3,50}$/);
      return pattern.test(surname);
    };

    /**
     * Checks if the employeeId is valid with same regex than backend
     *
     * @returns {boolean}
     */
    $scope.validateEmployeeId = function(employeeId) {
      var pattern = new RegExp(/^[_.@A-Za-z0-9-]{2,100}$/);
      return pattern.test(employeeId);
    };

    /**
     * Set the new password to the user
     * @param $e
     */
    $scope.setNewPassword = function($e) {
      $scope.errorNewPassword = null;

      if ($scope.newPasswordModel.password1 !== $scope.newPasswordModel.password2) {
        // Passwords don't match
        $scope.errorNewPassword = $scope.locale['front.login.error.passwordNotMatching'];
        $scope.highlightError();
        return;
      }

      if (!$scope.validatePassword($scope.newPasswordModel.password1)) {
        // Password invalid
        $scope.errorNewPassword = $scope.locale['front.login.error.invalidPassword'];
        $scope.highlightError();

        return;
      }

      var succeeded = function() {
        // Success
        $scope.isPasswordRecovered = true;
        $scope.newPasswordSet = true;
      };

      var failed = function(reason) {
        $scope.isPasswordRecovered = false;

        if (reason.status == 400 || reason.status == 500) {
          // Failed for one of the following reasons:
          // Invalid password
          // Invalid token/token expired
          $scope.errorNewPassword = $scope.locale['front.login.errorNewPassword'];
          $scope.highlightError();
        }
      };

      globalService.setNewPassword($scope.newPasswordModel.password1, $scope.newPasswordToken)
        .then(succeeded, failed);

      return false; // don't make a form redirection
    };

    /**
     * Highlights
     */
    $scope.highlightError = function() {
      $interval(function() {
        angular.element('.b-login .error-message').toggleClass('highlight').toggleClass('bounce-fast');
      }, 600, 2);
    };

    $scope.goHome = function() {
      $location.path("/home");
    };

    /**
     * Shows the password recovery form
     */
    $scope.forgotPassword = function() {
      $scope.passwordRecoveryMode = true;
      $scope.isPasswordRecovered = false;
      $scope.errorRecover = null;
    };

    /**
     * Hides the password recovery form and shows the default (the login form)
     */
    $scope.rememberedPassword = function() {
      $scope.passwordRecoveryMode = false;
    };

    /**
     * Callback to get back to the initial state
     */
    $scope.passwordRecovered = function() {
      $scope.passwordRecoveryMode = false;
      $scope.isPasswordRecovered = false;
    };

    /**
     * Shows the sign up form and hide all others
     */
    $scope.goToSignUpForm = function () {
      $scope.signUpMode = true;
    };


    /**
     * Returns to the login form from sign up form
     */
    $scope.returnFromSignUpForm = function() {
      $scope.signUpMode = false;
    };

    /**
     * Validate sign up form locally
     */
    $scope.validateSignUpData = function() {
      var validSignUpData = true;
      $scope.signUpMessages = [];
      if ($scope.signUpModel.nickname !== "" && $scope.signUpModel.nickname !== " " && !$scope.validateNickname($scope.signUpModel.nickname)) {
        validSignUpData = false;
        $scope.signUpMessages.push($scope.locale["front.signup.error.nickname"]);
      }
      if ($scope.signUpModel.surname !== "" && $scope.signUpModel.surname !== " " && !$scope.validateSurname($scope.signUpModel.surname)) {
        validSignUpData = false;
        $scope.signUpMessages.push($scope.locale["front.signup.error.surname"]);
      }
      if ($scope.signUpModel.employeeId !== "" && $scope.signUpModel.employeeId !== " " && !$scope.validateEmployeeId($scope.signUpModel.employeeId)) {
        validSignUpData = false;
        $scope.signUpMessages.push($scope.locale["front.signup.error.employeeId"]);
      }
      if ($scope.signUpModel.password1 !== null) {
        if (!$scope.validatePassword($scope.signUpModel.password1)) {
          // Password invalid
          $scope.signUpMessages.push($scope.locale['front.login.error.invalidPassword']);
          validSignUpData = false;
        } else if ($scope.signUpModel.password1 !== $scope.signUpModel.password2) {
          // Passwords don't match
          $scope.signUpMessages.push($scope.locale['front.login.error.passwordNotMatching']);
          validSignUpData = false;
        }
      }
      if (validSignUpData) {
        return true;
      } else {
        $scope.signUpSuccess = false;
        $scope.highlightError();
        return false;
      }
    };

    /**
     * Do the actual signup call to the API
     */
    $scope.signUp = function() {
      if ($scope.validateSignUpData()){
        // Make null empty values
        Object.keys($scope.signUpModel).forEach(function (key, index) {
          if ($scope.signUpModel[key] === "" || $scope.signUpModel[key] === " ") {
            $scope.signUpModel[key] = null;
          }
        });
        globalService.signUp($scope.signUpModel).then(function (response) {
          // Success
          if (response.status === 201) {
            // User created
            $scope.signUpMessages.push($scope.locale['front.signup.success']);
            $scope.signUpSuccess = true;
          }
          // Show success message and email verification advise

        }, function (reason) {

          if (reason.status === 400) {
            // Failed, show message from backend or get i18n default message
            var successMessage = $scope.locale['front.signup.error'];
            if (reason.data && reason.data !== "") {
              successMessage += ", " + reason.data;
            }
            $scope.signUpMessages.push(successMessage);
            $scope.signUpSuccess = false;
            $scope.highlightError();
          }
        });
      }

      return false; // do not make the form redirection
    };

    init();

  }
]);
