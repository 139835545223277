'use strict';

/* Log Service */

angular.module('game')
.factory('logService', ['config', '$log', function (config, $log) {

  var loggingService = log.noConflict();

  // Set the logging level from configuration
  loggingService.setDefaultLevel(config.loglevel || 'error');

  return loggingService;
}]);
