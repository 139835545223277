'use strict';

angular.module('game')
.controller('resultsCtrl', ['$scope', 'audioService', 'gameSettings', 'resultsServices', 'videoServices', 'angularServices', 'globalService', '$window',
  function ($scope, audioService, gameSettings, resultsServices, videoServices, angularServices, globalService, $window) {

    var $location = angularServices.$location;
    var $interval = angularServices.$interval;
    var contentURL = globalService.gameConstants.contentURL;
    var colorPrimary = gameSettings.settings.profile.statisticColor1;
    var colorSecondary = gameSettings.settings.profile.statisticColor2;

    this.globalService = globalService;
    this.$window = $window;

    $scope.canvasSupported = Utils.canvasSupported();
    $scope.menuImg = contentURL + 'images/common/icons/icon-menu-27x27.png';
    $scope.circleImg = contentURL + 'images/common/main/circle.png';

    $scope.totalPercentage = 0;

    $scope.$emit("pageChange", {page: "results"});

    // Handle sound on results page
    function initSound() {
      if (!audioService.audioPlayer().isPlaying() && audioService.audioPlayer().shouldBePlaying) {
        audioService.audioPlayer().shouldPlay(true);
        // var song = audioService.song;
        // var repeat = audioService.repeat;
        // audioService.remove();
        // audioService.setVolumeMax(gameSettings.globalAudio.default);
        // audioService.play(song, repeat);
      }
    }
    initSound();

    Utils.collapsible();

    $scope.$on("$destroy", function () {
      globalService.niceScrollRemove('.scrollbox');
    });

    $scope.home = function () {
      $location.path('/home');
    };

    function progressElement(value) {
      if (value > 0) {
        $scope.totalPercentage = 0;
        var percentageInterval = $interval(function () {
          angular.element("#percentageNumber").html($scope.totalPercentage + "%");
          if ($scope.totalPercentage == value)
            $interval.cancel(percentageInterval);
          else {
            $scope.totalPercentage = $scope.totalPercentage + 1;
            $scope.color = Utils.toRGB($scope.totalPercentage);
          }
        }, 10);
      }
    };

    videoServices.getDecalogueStatus().then(function (response) {
      var data = response.data;
      $scope.position = data.position;
      $scope.points = data.points;
      $scope.decalogues = data.decalogues;
      $scope.status = data.status;

      if ($scope.canvasSupported) {
        // var totalPercentageColor = Utils.toRGB(data.totalPercentage);
        var totalPercentageColor = colorPrimary;
        // var darkerPercentageColor = Utils.toRGB(data.totalPercentage, true);
        // var darkerPercentageColor = Utils.colorLuminance(colorPrimary, -0.5);
        var darkerPercentageColor = colorSecondary;

        var $canvas = $('#global-results');
        var ctx = $canvas[0].getContext('2d');

        // Add gradient color
        var gradient = ctx.createLinearGradient(0, 150, 0, 0);
        gradient.addColorStop(0, totalPercentageColor);
        gradient.addColorStop(1, darkerPercentageColor);

        var canvasData = {
          labels: ['Done', 'Pending'],
          datasets: [
            {
              data: [data.totalPercentage, 100 - data.totalPercentage],
              backgroundColor: [gradient, 'rgba(255,255,255,0)'],
              hoverBackgroundColor: [Utils.toRGB(data.totalPercentage, true)],
              borderWidth: 0,
            }
          ]
        };

        Chart.defaults.global.maintainAspectRatio = false;
        var chart = new Chart($canvas, {
          type: 'doughnut',
          data: canvasData,
          options: {
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
            cutoutPercentage: 72,
            scales: {
              width: 100,
            }
          }
        });

        // @todo This shadow gets cut by the canvas.
        // Canvas should be a bit bigger
        // ctx.shadowColor = 'rgba(0,0,0,0.1)';
        // ctx.shadowOffsetY = 3;
        // ctx.shadowOffsetX = 3;

      }

      if (angular.element(".ie8").length === 0) {
        progressElement(data.totalPercentage);
      }
      else {
        $scope.totalPercentage = data.totalPercentage;
        $scope.color = Utils.toRGB(data.totalPercentage);
      }

      resultsServices.startAnimation($scope.status);

    }).catch(function () {
      $location.path("#/home");
    });


    /**
     * Updates the scroll bar style considering the window height
     */
    var gridScroll = function (create) {

      if (create === undefined) {
        create = false;
      }

      var $window = angular.element(this.$window);
      var $header = angular.element('.page-template > div > .header');

      var viewPortMargin = angular.element('.view-port-content').css('margin-bottom');
      var $gridWrapper = angular.element('.infoBox.content.results');

      var windowHeight = $window.height();

      if (!Utils.isMobileOrTablet() && windowHeight < 768) {
        windowHeight = 768
      }

      var divergence = 0;

      if (Utils.isIE9()) {
        // IE9 has a difference with the height that can't be explained, neither fixed by CSS
        // so until we get the real problem, we'll fix it manually
        divergence = -3;
      }

      var gridHeight = windowHeight -
        $header.outerHeight(true) -
        parseInt(viewPortMargin) -
        parseInt($gridWrapper.css('margin-top')) -
        parseInt($gridWrapper.css('margin-bottom')) +
        divergence;

      if (gridHeight > 0) {
        $gridWrapper.height(gridHeight);
      } else {
        $gridWrapper.height(windowHeight);
      }

      if (create) {
        this.globalService.niceScroll('.results .nano');
      } else {
        this.globalService.niceScrollUpdate('.results .nano');
      }

    };

    gridScroll.bind(this)(true);
    $scope.$on('windowResize', gridScroll.bind(this));

  },
]);
