'use strict';

angular.module('game')
  .controller('assetCtrl', ['$scope', 'gameSettings', 'i18nService', '$routeParams', 'apiCall', '$location', 'playerService',
    function ($scope, gameSettings, i18nService, $routeParams, apiCall, $location, playerService) {

      $scope.file = undefined;
      $scope.type = undefined;

      $scope.autostartVideo = gameSettings.video.autostart && !Utils.isMobileOrTablet() && !Utils.isSafari() && !Utils.isChrome();

      i18nService.getLocale().then(function (locale) {
        $scope.locale = locale;
      });

      $scope.$emit("pageChange", {page: "asset"});

      $scope.home = function () {
        $location.path('/home');
      };


      // Hace petición al endpoint en el que se generan las cookies firmadas de cloudfront
      //  para poder acceder al contenido deseado.

      if ($routeParams['file'] !== undefined) {
        apiCall.get("api/1/asset/" + $routeParams["file"] + '/').then(function (response) {
          $scope.file = response.data.file;
          $scope.type = response.data.type;

          if ($scope.type === "video") {
            var videoPlayer = playerService.create("assetElement");
            if ($scope) {
              videoPlayer.attachToScope($scope);
            }
            var setup = {
              'file': $scope.contentURL + 'videos/assets/' + $scope.file,
              'mediaid': $scope.contentURL + 'videos/assets/' + $scope.file,
              'autostart': !Utils.isSafari() && !Utils.isChrome() && !Utils.isMobileOrTablet() && gameSettings.video.autostart,
              'controls': true,
              'tracks': [{
                'file': $scope.contentURL + 'videos/assets/' + $scope.file + ".vtt",
                'kind': "captions",
                "default": true
              }],
              'captions': gameSettings.settings.videoPlayer.settings.captions,
              events: {
                onReady: function () {
                  if ($scope.autostartVideo)
                    videoPlayer.play();
                },
              }
            };
            // Initialize player
            videoPlayer.setup(setup);

          }
        });
      }
    },
  ]);
