'use strict';

/* Services */

angular.module('game')
  .factory('apiCall', function ($http, timeoutPromise, petitionURL) {
    var api = {
      get: function (url, headers, extra) {
        if (!headers) {
          headers = {};
        }
        if (!extra) {
          extra = {};
        }

        var conf = {
          timeout: timeoutPromise(),
          headers: headers
        };

        angular.extend(conf, extra);
        return $http.get(petitionURL + url, conf);
      },
      post: function (url, data, headers, extra) {
        if (!headers) {
          headers = {};
        }
        if (!extra) {
          extra = {};
        }

        var conf = {
          timeout: timeoutPromise(),
          headers: headers
        };

        if (extra.handleAsForm) {
          conf.transformRequest = function (obj) {
            var str = [];
            for (var p in obj)
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            return str.join("&");
          };
        }

        angular.extend(conf, extra);
        return $http.post(petitionURL + url, data, conf);
      },
      put: function (url, data) {
        return $http.put(petitionURL + url, data, {
          timeout: timeoutPromise()
        });
      }
    };
    return api;
  })
;
