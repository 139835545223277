'use strict';

/* Localization */

angular.module('game').value('version', '0.1')
  .factory('i18nService', ['$q', 'apiCall', 'localStorageService', 'tenant', function ($q, apiCall, localStorageService, tenant) {

    return {
      locale: null,

      /**
       * It loads the locale into the $scope
       *
       * By default it loads it into a property called locale, but it can be modified using the parameter "where"
       *
       * @param $scope
       * @param where
       */
      loadLocale: function($scope, where) {
        if (!where) {
          where = 'locale';
        }

        this.getLocale().then(function(decalogue) {
          $scope[where] = decalogue;
        });
      },
      /**
       * Retrieves the locale information
       *
       * @param cache
       * @param language
       * @returns {*}
       */
      getLocale: function (cache, language) {
        if (cache == undefined) {
          cache = true;
        }

        if (this.locale == null || !cache) {
          var localStorageKey = 'locale_' + tenant;
          if (localStorageService.get(localStorageKey) != null && cache) {
            this.locale = localStorageService.get(localStorageKey);
          } else {
            var self = this;
            var q = $q.defer();

            var extra = {}
            if (language) {
              extra.params = {lang: language};
            }
            apiCall.get('api/1/pub/i18n/front', null, extra).then(function (response) {
              var locale = response.data;
              self.locale = locale;
              localStorageService.set(localStorageKey, locale);
              q.resolve(self.locale);
            });

            return q.promise;
          }
        }

        return $q.when(this.locale);
      }
    }
  }]);
