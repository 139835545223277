'use strict';

angular.module('game').factory('resultsServices', ['$timeout', function ($timeout) {

  return {
    //animate the progress bars from 0 to percentage value
    fillBars: function (value, $element, adjustColor) {

      if (adjustColor === undefined) {
        adjustColor = true;
      }

      $element.find(".barContent")
        .width("0%")
        .animate({
          specialEasing: {
            width: "linear"
          },
          width: value + "%"
        }, {
          duration: 1000,
          step: function (now) {
            if (!isNaN(now)) {
              $element.find(".percentageLittle").html(parseInt(now) + "%");

              if (adjustColor) {
                $element.find(".barContent").css("background", Utils.toRGB(now));
              }
            }
          }
        });
    },

    startAnimation: function (status) {
      var self = this,
        timer = 300;
      // angular.element(document).ready(function () {
        angular.forEach(status, function (value, key) {
          value["color"] = Utils.toRGB(value.percentage);
          timer = timer + 100;
          $timeout(function () {
            self.fillBars(value.percentage, angular.element("#d" + key))
          }, timer);
        });
      // })
    },

  }

}]);
