'use strict';

/* Controller for grid view */
angular.module('game').controller('HomeCtrl', [
  '$scope',
  '$rootScope',
  'audioService',
  'globalService',
  'apiCall',
  '$interval',
  '$timeout',
  'i18nService',
  'homeServices',
  'contentURL',
  'config',
  'gameSettings',
  '$location',
  '$window',
  function ($scope, $rootScope, audioService, globalService, apiCall, $interval, $timeout, i18nService, homeServices, contentURL, config, gameSettings, $location, $window) {
    var classInterval = null;
    var audioPlayer = null;
    var timeout = null;
    var intervalRandom = null;
    var intervalPromise = null;
    var positiveArray = null;

    /**
     * Initializes the controller
     */
    function init () {
      // Handle sound on home page
      initSound();

      $scope.$emit("pageChange", {page: "home"});

      $scope.showInactive = false;
      $scope.numberOfSeasons = 1;

      /* CHEK IF THERE IS PREVIOUS UNLOCKED CHALLENGES*/
      $scope.checkChallenges = checkChallenges.bind(this);

      /* FUNCTION TO UNLOCK A CHALLENGE AFTER THE RANDOM EFFECT ON THE GRID */
      $scope.randomSelection = randomSelection.bind(this);

      /* FUNCTION TO BE CALLED WHEN SEASON CHANGES. REFRESH THE GRID AND USER STATUS CORRESPONDING TO THE NEW LEVEL */
      $scope.changeSeason = changeSeason.bind(this);

      $scope.mouseOverChallengePreview = mouseOverChallengePreview.bind(this);

      $scope.$on("$destroy", function (event) {
        $interval.cancel(intervalPromise);
        $interval.cancel(classInterval);
        $interval.cancel(intervalRandom);
        $timeout.cancel(timeout);
        // audioService.remove();
      });

      // audioService.remove();
      getSeason();
      gridScroll(true);

      $scope.updateScroll = function() {
        gridScroll();
      };

      $scope.$on('windowResize', gridScroll.bind(this));
      $scope.$on('seasonChanged', changeSeason.bind(this));
      $scope.$on('levelSwitcherListener', function (event, action) {
        action.shouldDisplay = true;
      });

      // When loading home controller disable fullscreen
      if (globalService.getFullscreenState()) {
        globalService.toggleFullscreen(false);
      }

    }

    function initSound() {
      if (!audioService.audioPlayer().isPlaying() && audioService.audioPlayer().shouldBePlaying) {
        var song = audioService.song;
        var repeat = audioService.repeat;
        audioService.remove();
        audioService.setVolumeMax(gameSettings.globalAudio.default);
        audioService.play(song, repeat);
      }
    }

    /**
     * Updates the scroll bar style considering the window height
     */
    function gridScroll (create) {
      if (create === undefined) {
        create = false;
      }

      var $$window = angular.element($window);
      var $header = angular.element('.page-template > div > .header');

      var viewPortMargin = angular.element('.view-port-content').css('margin-bottom');
      var $gridWrapper = angular.element('.grid-wrapper');

      var windowHeight = $$window.height();

      if (!Utils.isMobileOrTablet() && windowHeight < 768) {
        windowHeight = 768
      }

      var divergence = 0;

      if (Utils.isIE9()) {
        // IE9 has a difference with the height that can't be explained, neither fixed by CSS
        // so until we get the real problem, we'll fix it manually
        divergence = -3;
      }

      var gridHeight = windowHeight -
        $header.outerHeight(true) -
        parseInt(viewPortMargin) -
        parseInt($gridWrapper.css('margin-top')) -
        parseInt($gridWrapper.css('margin-bottom')) +
        divergence;

      $gridWrapper.height(gridHeight);

      if (create) {
        globalService.niceScroll('.grid-wrapper.nano', false);
      } else {
        globalService.niceScrollUpdate('.grid-wrapper.nano');
      }

    }

    /**
     * Gets the next session to play
     */
    function getSeason () {
      apiCall.get("api/1/common/season").then(function (response) {
        var seasonData = response.data;

        $scope.numberOfSeasons = seasonData.numberOfSeasons;

        $scope.firstTime = false;

        $scope.showButton = config.mainButton;
        $scope.seasonsCache = seasonData.seasons;

        /*   MAIN   */
        initializeGrid(seasonData);

        if (gameSettings.randomSelection) {
          $timeout(function () {
            randomSelection();
          }, 1000);
        }

      })
    }

    /**
     * SET SCOPE VARS AND LOCALSTORAGE DATA
     * @param seasonData
     */
    function initializeGrid (seasonData) {
      var currentTime = new Date().getTime();

      homeServices.unlocking = false,
        $scope.arrowRight = contentURL + 'images/common/icons/arrowRight.png',
        $scope.arrowLeft = contentURL + 'images/common/icons/arrowRight.png',
        $scope.contentURL = contentURL;

      if (gameSettings.randomSelection) {
        audioPlayer = homeServices.unlockAudioStart();
      }

      positiveArray = (seasonData.nextChallengeSentences && seasonData.nextChallengeSentences.length) ? seasonData.nextChallengeSentences : null;

      if (seasonData.currentLevel == null) {
        seasonData.currentLevel = 1;
      }

      var currentSeason = $rootScope.currentSeason = seasonData.currentLevel;
      $scope.lastSeason = seasonData.currentLevel;

      if (seasonData.seasons[currentSeason] != null && seasonData.seasons[currentSeason].soundResources != null) {
        audioService.setVolumeMax(gameSettings.globalAudio.default);
        audioService.soundResources = seasonData.seasons[currentSeason].soundResources;
        var soundResource = Utils.rand(seasonData.seasons[currentSeason].soundResources);
        if (soundResource && !audioService.audioPlayer().isPlaying()){
          audioService.play(soundResource.url, soundResource.bucle);
        }
      }

      $scope.currentSeasonName = seasonData.seasons[currentSeason].nameI18n;

      if (seasonData.firstChallengeSentences == null) {
        $scope.positiveFeedback = (seasonData.nextChallengeSentences && seasonData.nextChallengeSentences.length) ? Utils.rand(seasonData.nextChallengeSentences) : ["1,2,3"];
      } else {
        angular.element(".up").css("left", angular.element(".view").offset().left + 200)
        $scope.positiveFeedback = Utils.rand(seasonData.firstChallengeSentences);
      }

      $scope.negativeFeedback = (seasonData.waitChallengeSentences[0] != null) ? seasonData.waitChallengeSentences[0] : "1";

      apiCall.get("api/1/grid/" + seasonData.seasons[currentSeason].id).then(function (response) {
        var grid = response.data;
        $scope.completed = grid.status.completed;
        $scope.status = grid.status;

        $scope.customers = processCustomers(grid.customers);
        $scope.date = (grid.status.updateTime != null) ? grid.status.updateTime : currentTime + 30000;

        if (grid.status.credits == 0) {
          refreshDate($scope.date, 30000);
        }

        gridScroll();

        classInterval = homeServices.toggleClassInterval(".unlockButton", "blink", 800);
        $timeout(function () {
          $scope.firstTime = angular.element('.challenge-img.failure').length == 0 && angular.element('.challenge-img.success').length == 0;
          if ($scope.firstTime) {
            var challengeAvailable = angular.element(".challenge:not(.locked):first");
            var down = angular.element(".down");

            if (challengeAvailable.length) {
              var boundings = challengeAvailable.offset();
              challengeAvailable.css('z-index', 999).css('border', '2px solid gray');
              down.css("top", boundings.top - 170);
              down.css("left", boundings.left);
              down.css("display", "block");
              //angular.element("#c" + challengeId).css("z-index", 4)
              /* IF FIRST TIME*/
            } else {
              down.hide();
            }
          }
        }, 500);
      });

    }

    /**
     * It will add a new variable to know if a project is locked, because it hasn't been played a dependency
     *
     * @param customers
     */
    function processCustomers (customers) {
      angular.forEach(customers, function(customer) {
        angular.forEach(customer.projects, function(project) {
          project.businessLocked = project.businessRule != null && project.businessRule.locked;

          if (project.businessLocked) { // If the project is locked for business reasons then
            project.locked = true;  // the project should be locked
          }
          angular.forEach(project.challenges, function(challenge) {

            if (project.locked) { // If the project is locked
              challenge.locked = true; // then its challenges are locked
            }

          });
        });
      });

      return customers;
    }

    /**
     * REFRESH COUNTDOWN FUNCTION. IT WILL STOP AT 0,0,0 AND MAKE ANOTHER PETITION
     * @param targetDate
     * @param refreshTime
     */
     function refreshDate (targetDate, refreshTime) {
      if (intervalPromise != null) {
        $interval.cancel(intervalPromise);
      }

      if ($rootScope.currentSeason == $scope.lastSeason) {
        var hours, minutes, seconds, current_date, seconds_left, target_date = targetDate;


        intervalPromise = $interval(function () {
          var countdown = document.getElementById("countdown");
          if (countdown == null && $scope.status.credits == 0) {
            $interval.cancel(intervalPromise);
          } else {
            current_date = new Date().getTime();
            seconds_left = (target_date - current_date) / 1000;
            seconds_left = seconds_left % 86400;
            hours = parseInt(seconds_left / 3600);
            seconds_left = seconds_left % 3600;
            minutes = parseInt(seconds_left / 60);
            seconds = parseInt(seconds_left % 60);

            if (seconds <= 0 && minutes <= 0 && hours <= 0) {
              $interval.cancel(intervalPromise);
              apiCall.get("api/1/grid/status/" + $rootScope.currentSeason).then(function (response) {
                var data = response.data;
                var currentTime = new Date().getTime();
                if (data.updateTime != null && data.updateTime > currentTime) {
                  $scope.date = data.updateTime;
                  $scope.status = data;
                  if ($scope.status.credits == 0) {
                    refreshDate($scope.date, 30000);
                  }

                } else {
                  $scope.date = currentTime + refreshTime;
                  $scope.status = data;
                  refreshTime = refreshTime * 2;
                  refreshDate($scope.date, refreshTime);
                }

              })
            }
          }
        }, 1000);
      }
    }

    /**
     * Returns challenge information
     *
     * @param projectId
     * @param challengeId
     * @returns {boolean}
     */
    function getChallengeInfo (projectId, challengeId) {
      var challengeFound = false;
      angular.forEach($scope.customers, function(customer) {
        angular.forEach(customer.projects, function(project) {
          if (project.id == projectId) {
            angular.forEach(project.challenges, function(challenge) {
              if (challenge.id == challengeId) {
                challengeFound = challenge;
                return false;
              }
            });
          }

          if (challengeFound) {
            return false;
          }
        });

        if (challengeFound) {
          return false;
        }
      });

      return challengeFound;
    }

    /**
     * CHEK IF THERE IS PREVIOUS UNLOCKED CHALLENGES
     * @param level
     * @param chapter
     */
     function checkChallenges (level, chapter) {

      if (homeServices.unlocking == false) {
        var challengeInfo = getChallengeInfo(level, chapter);

        if (challengeInfo && !challengeInfo.locked && !challengeInfo.timeLocked) {
          $location.path("/challenge/" + chapter);
        }
        else if (challengeInfo.timeLocked) {
          showTimeBlockedPopup()
        }
      }
    }

    function showTimeBlockedPopup () {
      $scope.showChallengeBlockedPopup($scope.status.maxChallenges, $scope.status.challengeInterval)
    }

    /**
     * FUNCTION TO UNLOCK A CHALLENGE AFTER THE RANDOM EFFECT ON THE GRID
     */
    function randomSelection () {
      if (!homeServices.unlocking && $scope.status.credits > 0) {
        apiCall.get("api/1/grid/next/" + $rootScope.currentSeason).then(function (response) {
          var dataNext = response.data;
          if (dataNext.challenge != undefined && $scope.status.credits > 0) {

            if ($scope.firstTime) {
              angular.element(".up").css("display", "none");
            }
            angular.element(".unlockButton").addClass("buttonDisabled");

            homeServices.unlocking = true;
            $scope.positiveFeedback = Utils.rand(positiveArray);

            var button = document.querySelector("button");
            if (button != null) {
              button.className = button.className + "disabled";
            }

            if (dataNext.status.updateTime != null && dataNext.status.credits == 0) {
              $scope.date = dataNext.status.updateTime;
              refreshDate($scope.date, 30000);
            }

            if ($(".level.available").length == 1 || !gameSettings.randomSelection) {
              homeServices.finalSelection(dataNext.challenge.id, dataNext.status.credits)
            }
            else {
              intervalRandom = homeServices.randomize(dataNext.challenge.id, audioPlayer, dataNext.status.credits, $scope.firstTime || false);
            }

            $scope.status = dataNext.status;

          } else if ($scope.status.credits > 0) {
            $scope.showError($scope.negativeFeedback, true);
          }
        });

      } else {
        $scope.showInactive = true;
      }
    }

    /**
     * FUNCTION TO BE CALLED WHEN SEASON CHANGES. REFRESH THE GRID AND USER STATUS CORRESPONDING TO THE NEW LEVEL
     * @param data
     */
    function changeSeason (event, newSeason, data) {
      $scope.customers = processCustomers(data.customers);
      timeout = $timeout(function () {
        $scope.status = data.status;
        $rootScope.currentSeason = newSeason;
        audioService.setVolumeMax(gameSettings.globalAudio.default);
        audioService.soundResources = $scope.seasonsCache[$rootScope.currentSeason].soundResources;

        var soundResource = Utils.rand($scope.seasonsCache[$rootScope.currentSeason].soundResources);
        audioService.play(soundResource.url, soundResource.bucle);

        refreshDate($scope.date, 30000);

        gridScroll();
      }, 100);
    }

    /**
     * FUNCTION TO BE CALLED WHEN CHALLENGE PREVIEW IS HOVERED
     * @param challengeImageId
     * @param enter
     */
    function mouseOverChallengePreview (challengeImageId, enter) {
      if (gameSettings.settings.challengesPreviewGifs) {
        var challengePreviewElement = angular.element('#'+challengeImageId);
        var src = challengePreviewElement.attr("src");
        if (enter) {
          challengePreviewElement.attr("src", src.replace(/\.png$/i, ".gif"));
        } else {
          challengePreviewElement.attr("src", src.replace(/\.gif$/i, ".png"));
        }
      }
    }

    init();
  }
]);



