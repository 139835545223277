angular.module('game', [
    'LocalStorageModule', //Local storage support for angular
    'ngRoute',
    'templates'
  ])
  .provider('gameSettings', function () {
    // Defines game settings
    var config = {
      video: {
        autostart: true,
        mute: false,
        skin: false,
        subtitles: {
          active : false,
          autostart: false
        },
        fullscreen: {
          active: false
        }
      },
      home: {
        randomSelection: true,
      },
      intro: {
        videoResponsive: false,
        signUpEnabled: false
      },
      rewards: {
        active: false,
      },
      globalAudio: {
        default: 100,
        challenge: 65,
      },
      login: {
        redirectToChallenge: true, // On first time login, the user might be redirected to its next challenge by default
      },
      settings: {
        // Custom settings to be overriden by tenants
        profile: {
          statisticColor1: '#0099FF', // Main color used for the global statistic degrade
          statisticColor2: '#004d80', // Secondary color used for the global statistic degrade
        },
        mainColor: '#0099FF', // Main color of the project,
        languages: ['es-ES'],
        ranking: {
          userLogo: false, // Defines a custom logo for the ranking widget
          hasCustomRanking: true, // Defines if there's a custom ranking in this tenant
          hasGlobalRanking: true,  // Defines if must show global ranking in this tenant
          hasFriendsRanking: true
        },
        client: {
          logo: 'images/common/v2/main/header/client-desktop.png', // Defines the client logo
          name: 'Aiwin', // Name of the client
        },
        site: {
          logo: 'images/common/v2/main/header/project-logo-desktop.png', // Defines the project logo
          name: 'E-motion', // Name of the site
        },
        failureBehaviour: {
          mode: 'showStatisticsAfterTime',
          time: 5
        },
        timerBar : {
          gradient: true,
          colorPrimary: 'green',
          colorSecondary: 'red'
        },
        challengesPreviewGifs: false,
        showPreviewOnFinishWithZeroPoints: true,
        videoPlayer: {
          name: 'jwplayer',
          location: 'jwplayer',
          settings: {
            captions: { // Default captions settings values
              color: '#ffffff',
              fontSize: 15,
              fontFamily: 'arial',
              fontOpacity: 100,
              backgroundColor: '#000000',
              backgroundOpacity: 70,
              edgeStyle: 'none',
              windowColor: '#000000',
              windowOpacity: 0
            }
          }
        }
      }
    };

    return {
      $get: function () {
        return config;
      }
    }
  })
  .config(['$sceDelegateProvider', '$routeProvider', '$httpProvider', 'mediaURL', '$locationProvider',
    function ($sceDelegateProvider, $routeProvider, $httpProvider, mediaURL, $locationProvider) {

      $httpProvider.defaults.withCredentials = true;
      $httpProvider.defaults.headers.common['If-Modified-Since'] = '0';
      $sceDelegateProvider.resourceUrlWhitelist(['self', mediaURL + '**']);
      $locationProvider.hashPrefix('');
      $routeProvider.when('/results', {
        templateUrl: 'app/partials/results-page/results-page.html',
        title: ' Statistics '
      });
      $routeProvider.when('/home', {
        templateUrl: 'app/partials/home-page/home-page.html',
        title: ' Home '
      });
      $routeProvider.when('/login', {
        templateUrl: 'app/partials/login-page/login-page.html',
        title: ' Login '
      });
      $routeProvider.when('/challenge/:id', {
        templateUrl: 'app/partials/challenge-page/challenge-page.html',
        title: ' Challenge '
      });
      $routeProvider.when('/', {
        templateUrl: 'app/partials/intro-page/intro-page.html',
        title: ' Aiwin '
      });
      $routeProvider.when('/password/:reset/:token', {
        templateUrl: 'app/partials/intro-page/intro-page.html',
        title: ' Aiwin '
      });
      $routeProvider.when('/password/:reset', {
        templateUrl: 'app/partials/intro-page/intro-page.html',
        title: ' Password reset '
      });
      $routeProvider.when('/account/:activation/:activationToken', {
        templateUrl: 'app/partials/intro-page/intro-page.html',
        title: ' Aiwin '
      });
      $routeProvider.when('/error', {
        templateUrl: '404.html',
        title: ' ¡Ups! '
      });
      $routeProvider.when('/asset/:file', {
        templateUrl: 'app/partials/asset-page/asset-page.html',
        title: ' Asset ',
        redirectOnLogin: true
      });
      $routeProvider.otherwise({
        redirectTo: '/'
      });

    }
  ])
  .run(function ($rootScope, i18nService, contentURL) {

    i18nService.getLocale(false).then(function(i18n) { // pre-cache localStorage and set it global
      $rootScope.locale = i18n;
    });

    $rootScope.isMobile = Utils.isMobile(); // Make this global
    $rootScope.isTablet = Utils.isTablet(); // Make this global

    $rootScope.contentURL = contentURL; // Make this global

  })
  .run(function ($rootScope, $timeout, $window, $location, $route, config, gameSettings, globalService, localStorageService, tenant) {

    if (config.GACode) {
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date();
        a = s.createElement(o),
          m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m)
      })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
      var timerTitle;

      ga('create', config.GACode, 'auto');
    }

    $rootScope.$on('$routeChangeSuccess', function (event, nextRoute, currentRoute) {

      if (timerTitle != null)
        $timeout.cancel(timerTitle);

      timerTitle = $timeout(function () {
        var pageTitle = gameSettings.settings.site.name;
        if ($route.current.title && $route.current.title !== '') {
          pageTitle += " | " + $route.current.title;
        }
        $window.document.title = pageTitle;
      }, 600);

      if (config.GACode) {
        ga('send', 'pageview', $location.path());
      }

      // If accessing a redirectOnLogin route, then save the requested path in localstorage in order to get it on success login
      if (nextRoute && nextRoute.redirectOnLogin !== undefined && nextRoute.redirectOnLogin === true
        && currentRoute === undefined && nextRoute.params !== undefined) {
        globalService.loggedIn().then(function (loggedIn) {
          if (!loggedIn) {
            var refererUrl = nextRoute.$$route.originalPath;
            Object.entries(nextRoute.params).forEach(function(param){
              const re = new RegExp(":" + param[0], "g");
              refererUrl = refererUrl.replace(re, param[1]);
            });
            localStorageService.set("login_referer_url_" + tenant, refererUrl);
          }
        });
      }

    });

  });
