'use strict';

/* Delete Friend Directive */

angular.module('game')
.directive('deleteFriend', function() {
  return {
    restrict: 'A',
    templateUrl: 'app/partials/sidebar/ranking-block/delete-friend/delete-friend.html',
    scope: {
    },
    controller: function($scope, i18nService, rankingService) {

      $scope.deleteFriendPopupAPI = null;

      i18nService.getLocale().then(function(decalogue) {
        $scope.buttonOk = decalogue['front.terms.ok'];
        $scope.deleteMessage = decalogue['front.challenge.delete'];
      });

      $scope.removeFriendship = function() {
        rankingService.deleteFriend($scope.id);
        $scope.deleteFriendPopupAPI.close();
      };

      $scope.$parent.openPopupDeleteFriend = function(id) {
        $scope.id = id;
        $scope.deleteFriendPopupAPI.open();
      };

      // Get the popup API
      $scope.$watch('deleteFriendPopupAPI', function() {
        if ($scope.deleteFriendPopupAPI) {
          $scope.$parent[$scope.popupAttr] = $scope.deleteFriendPopupAPI.addClass('delete-friend');
        }
      });

    },
    link: function(scope, element, attrs) {

      scope.element = angular.element(element);
      var helpAttr = attrs['help'];

      // This is the attribute that will expose the API to the wrapper controller
      if (!helpAttr) {
        helpAttr = 'deleteFriendPopupAPI';
      }
      scope.popupAttr = helpAttr;

    }
  };
});
