'use strict';

/* Help Directive */

angular.module('game')
.directive('help', function() {
  return {
    restrict: 'A',
    templateUrl: 'app/partials/header/help/help.html',
    scope: {},
    controller: function($scope, globalService, i18nService, $location, contentURL) {

      i18nService.getLocale().then(function (locale) {
        $scope.helpText = locale['front.terms.help'];
        $scope.helpUrl = contentURL + locale['front.help.' + $location.path().replace(/[0-9]/g, "").replace("/", "").replace("/", "")];
      });

      // This is the property that will be retrieved from the inner popup directive
      $scope.$watch('helpPopupAPI', function() {
        if ($scope.helpPopupAPI) {
          $scope.$parent[$scope.popupAttr] = $scope.helpPopupAPI.addClass('help-container');
        }
      })
    },
    link: function(scope, element, attrs) {

      scope.element = angular.element(element);
      var helpAttr = attrs['help'];

      // This is the attribute that will expose the API to the wrapper controller
      if (!helpAttr) {
        helpAttr = 'helpPopupAPI';
      }
      scope.popupAttr = helpAttr;

    }
  };
});
