'use strict';

angular.module('game').controller('sidebarCtrl', ['$scope', 'globalService',
  function ($scope, globalService) {

    //globalService.niceScroll('.scrollBox');

    $scope.sidebarOpen = false;

    $scope.hideSidebar = function() {
      $scope.sidebarOpen = false;
    };

    $scope.openSidebar = function() {
      $scope.sidebarOpen = true;
    };

  }
]);
