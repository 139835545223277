'use strict';

/* Filters */

angular.module('game')
  .filter('interpolate', ['version', function (version) {
    return function (text) {
      return String(text).replace(/\%VERSION\%/mg, version);
    };
  }])
  .filter('textFormat', ['$interpolate', function ($interpolate) {
    return function (text) {
      var parameters = Array.prototype.slice.call(arguments, 1);
      var context = {};

      if (!text) {
        return text;
      }

      if (parameters.length % 2 == 1) {
        return '';
      }
      var index = 0;
      var valueIndex = 1;

      while(valueIndex < parameters.length) {
        context[parameters[index]] = parameters[valueIndex];
        index++;
        valueIndex++;
      }

      var exp = $interpolate(text);
      return exp(context);
    };
  }])
  .filter('to_trusted', ['$sce', function ($sce) {
    return function (text) {
      text = text || '';
      return $sce.trustAsHtml(text);
    };
  }])
  .filter('number_with_dots', [function () {

    /**
     * Removes the decimals from a string number
     *
     * The condition is if the decimals are equal to 0
     *
     * @param number
     * @returns {*}
     */
    var removeDecimals = function(number) {

      /**
       * Applies the locale string format
       *
       * @param number
       * @returns {*}
       */
      var format = function(number) {
        try {
          return number.toLocaleString(undefined, {maximumFractionDigits: 0}); // IE9 doesn't respect this parameter
        } catch (e) {
          // Unsupported toLocaleString()
          return number;
        }
      };

      var stringNumber = format(number);
      var zero = format(0);

      if (zero.length == 1) {
        return stringNumber;
      } else {
        var decimals = zero.slice(1);
        var position = stringNumber.indexOf(decimals);
        return stringNumber.slice(0, position);
      }
    };

    return function (number) {
      if (parseInt(number) >= 1000) {
        return removeDecimals(number);
      } else {
        return number;
      }
    };
  }]);
