'use strict';

/* Services */

angular.module('game').value('version', '0.1')
  .factory('angularServices', ['$rootScope', '$route', '$routeParams', '$location', '$timeout', '$interval', '$window',
    function ($rootScope, $route, $routeParams, $location, $timeout, $interval, $window) {
      return {
        $rootScope: $rootScope,
        $route: $route,
        $routeParams: $routeParams,
        $location: $location,
        $interval: $interval,
        $timeout: $timeout,
        $window: $window
      };
    }
  ])
;
