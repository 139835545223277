'use strict';

/* challengeTimeBlockedPopup Controller */
angular.module('game')
.directive('challengeTimeBlockedPopup', function() {
  return {
    restrict: 'A',
    templateUrl: 'app/partials/home-page/popup/challenge-time-blocked-popup.html',
    scope: {},
    controller: function ($scope, $rootScope, i18nService) {

      /**
       * Shows the time blocked challenge popup
       *
       * This is exposed t all the site through scope inheritance
       *
       * @param maxChallenges
       * @param temporalUnitType
       */
      $rootScope.showChallengeBlockedPopup = function(maxChallenges, temporalUnitType) {

        if (maxChallenges !== null && maxChallenges !== undefined && temporalUnitType !== null && temporalUnitType !== undefined) {
          i18nService.getLocale().then(function (locale) {
            $scope.challegeTimeBlockedTitle = locale['front.timeLockedPopup.title'];
            var challenge_count = 'multi';
            if (maxChallenges === 1) {
              challenge_count = 'single';
            }
            $scope.challengeTimeBlockedMessage = locale['front.timeLockedPopup.' + challenge_count + '.' + temporalUnitType];
            $scope.challengeTimeBlockedRetryMessage = locale['front.timeLockedPopup.retry.' + temporalUnitType]
          });
          $scope.maxChallengesForBlock = maxChallenges;

          $scope.challengeTimeBlockedPopupAPI.open();
        }
      };

      // Get the popup API
      $scope.$watch('challengeTimeBlockedPopupAPI', function() {
        if ($scope.challengeTimeBlockedPopupAPI) {
          $scope.$parent[$scope.popupAttr] = $scope.challengeTimeBlockedPopupAPI.addClass('challenge-time-blocked-popup-container');
        }
      });

    },
    link: function(scope, element, attrs) {

      scope.element = angular.element(element);
      var timeBlockedAttr = attrs['challenge-time-blocked-popup'];

      // This is the attribute that will expose the API to the wrapper controller
      if (!timeBlockedAttr) {
        timeBlockedAttr = 'challengeTimeBlockedPopupAPI';
      }
      scope.popupAttr = timeBlockedAttr;

    }
  }
});
