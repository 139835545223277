'use strict';

/* Services */

angular.module('game').value('version', '0.1')
.factory('audioService', ['$q', 'contentURL', '$timeout', 'gameSettings', 'playerService',
  function ($q, contentURL, $timeout, gameSettings, playerService) {

  // Determines if the player should be playing or not
  var state = 0;

  function getAudioPlayer(create) {
    if (create === undefined) {
      create = true;
    }

    var audioTrack = 'audioTrack';
    var $audioTrack = angular.element('#' + audioTrack);

    if (create && $audioTrack.length === 0) {
      $audioTrack = $('<div id="' + audioTrack + '">');
      $('<div class="audiotrack-wrapper">')
      .append($audioTrack).appendTo('body');
    }
    return playerService.create(audioTrack);
  }

  /* Initialize the back song */
  function startBackSong(audio, repeat) {

    var player = getAudioPlayer();

    var setup = {
      'file': contentURL + audio,
      'width': '400px',
      'height': '300px',
      'start': '6',
      'autostart': false,
      'controls': true,
      'volume': 0,
      'repeat': repeat,
      'events': {
        onPause: function () {
          if (state) {
            audioPlayer.play();
          }
        },
      }
    };
    var audioPlayer = player.setup(setup);

    return audioPlayer;
  }

  return {
    repeat: false,
    song: null,
    shouldBePlaying: null,
    maxVolume: gameSettings.globalAudio.default,
    setVolumeMax: function (maxVolume) {
      this.maxVolume = maxVolume;

      var audioPlayer = getAudioPlayer();
      if (audioPlayer.isPlaying() && audioPlayer.getVolume() != this.maxVolume) {
        audioPlayer.setVolume(this.maxVolume);
      }
    },
    shouldPlay: function (play) {

      if (!Utils.isMobileOrTablet()) {
        this.shouldBePlaying = play;
      }

      if (play) {
        this.play();
      }
    },
    play: function (song, repeat) {
      if (repeat !== undefined) {
        this.repeat = repeat;
      }

      if (song) {
        this.song = song;
        startBackSong(song, this.repeat);
      }
      state = 1;
      var audioPlayer = getAudioPlayer();

      if (this.song != null && this.shouldBePlaying) {
        if (!audioPlayer.isPlaying()) {
          audioPlayer.play();
        }
        if (!audioPlayer.getVolume() || audioPlayer.getVolume() != this.maxVolume) {
          audioPlayer.setVolume(this.maxVolume);
        }
      }
    },
    stop: function () {
      var audioPlayer = getAudioPlayer();
      if (audioPlayer.isPlaying()) {
        audioPlayer.setVolume(0);
      }
      this.shouldPlay(false);
      state = 0;
      audioPlayer.pause();
    },
    remove: function () {
      var audioPlayer = getAudioPlayer(false);
      state = 0;
      if (audioPlayer) {
        this.stop();
        this.song = undefined;
        this.repeat = false;
        try {
          audioPlayer.stop();
          // audioPlayer.remove();
        } catch (e) {
          e;
        }
      }
    },
    audioPlayer: function() {
      return getAudioPlayer();
    },
    soundResources: []
  }
}])
;
