'use strict';

/* Streaming */

angular.module('game').value('version', '0.1')
.factory('socketService', ['petitionURL', '$rootScope', '$timeout', 'globalService', 'DEBUG_INFO_ENABLED', 'tenant',
  function (petitionURL, $rootScope, $timeout, globalService, DEBUG_INFO_ENABLED, tenant) {

    function refreshStreaming(data) {
      angular.element(".boxTitle.streamTitle").addClass("shake");
      $timeout(function () {
        angular.element(".boxTitle.streamTitle").removeClass("shake");
      }, 600);

      if (data == null) {
        data = [];
      }
      $rootScope.streaming = data;
      $timeout(function () {
        globalService.niceScrollUpdate('.streaming-info .nano');
      }, 600);
    }

    function addStream(data) {
      function toDate(time) {
        var date = new Date(time),
            hours, minutes;
        if ((date.getMinutes() + "").length == 1) minutes = "0" + date.getMinutes();
        else minutes = date.getMinutes();
        if ((date.getHours() + "").length == 1) hours = "0" + date.getHours();
        else hours = date.getHours();
        return hours + ":" + minutes
      }
      angular.element(".boxTitle.streamTitle").addClass("shake");
      $timeout(function () {
        angular.element(".boxTitle.streamTitle").removeClass("shake");
      }, 600);

      if (data == null) {
        data = [];
      }
      data[0].class = 'hidden';

      if ($rootScope.streaming == null) {
        $rootScope.streaming = [];
      }

      $rootScope.streaming.unshift(data[0]);

      $timeout(function () {
        angular.element(".infoBox.streaming .rankingBox.stream.hidden").slideDown(function() {
          data[0].class = '';
          globalService.niceScrollUpdate('.streaming-info .nano');
        });
      }, 1000);

    };

    var protocols = ['websocket', 'xdr-streaming', 'xhr-streaming', 'xdr-polling', 'xhr-polling', 'iframe-eventsource', 'iframe-htmlfile'];
    var options = {
      // protocols_whitelist: protocols,
      debug: DEBUG_INFO_ENABLED,
      jsessionid: true
    };
    var socket = new SockJS(petitionURL + 'websocket', null, options);
    var stompClient = Stomp.over(socket);
    stompClient.debug = DEBUG_INFO_ENABLED;

    stompClient.connect({}, function (frame) {
      stompClient.subscribe('/app/stream.' + tenant, function (greeting) {
        refreshStreaming(JSON.parse(greeting.body));
      });
      stompClient.subscribe('/topic/event.' + tenant, function (greeting) {
        addStream(JSON.parse(greeting.body));
      });
    });

    return {
      refreshStreaming: refreshStreaming,
      addStream: addStream,
      listen: Utils.noop,
    };
  }
])
;
