'use strict';

angular.module('game').controller('rewardsCtrl', ['$scope', 'i18nService', 'contentURL', 'globalService', 'angularServices', 'rewardsService', 'resultsServices',
  function ($scope, i18nService, contentURL, globalService, angularServices, rewardsService, resultsServices) {

    $scope.contentURL = contentURL;

    $scope.rewardsActive = rewardsService.rewardsActive();

    var init = function() {

      Utils.collapsible();

      rewardsService.getRewardsStatus().then(function(data) {
        $scope.decalogues = data;
      });

      i18nService.getLocale().then(function (locale) {
        $scope.locale = locale;
      });

    };

    if ($scope.rewardsActive) {
      init();
    }


    /***** Scope Methods *******/
    $scope.fillBars = function($event) {
      var $target = angular.element($event.target);
      if (!$target.hasClass('barsFilled')) {
        $target.addClass('barsFilled');
        var timer = 50;
        $target.parent().find('.percentage-wrapper').each(function() {
          var $this = angular.element(this);
          timer = timer + 100;

          angularServices.$timeout(function () {
            resultsServices.fillBars($this.data('percentage'), $this, false);
          }, timer);
        });
      }
    }
  }
]);
