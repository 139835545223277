'use strict';

angular.module('game').controller('streamingCtrl', ['$scope', '$rootScope', 'contentURL', '$window', 'socketService', 'globalService',
  function ($scope, $rootScope, contentURL, $window, socketService, globalService) {

    $scope.contentURL = contentURL;
    $scope.$root = $rootScope;

    //
    socketService.listen();

    // Used for presenting each streaming information
    $scope.toDate = function (time) {
      var date = new Date(time);
      var minutes = (date.getMinutes() + "").length == 1 ? "0" + date.getMinutes() : date.getMinutes();
      var hours = (date.getHours() + "").length == 1 ? "0" + date.getHours() : date.getHours();
      return hours + ":" + minutes;
    };

    $scope.gridScroll = function(info) {

      var window = angular.element($window);

      var $userInfoContainer = angular.element('.user-info').parent();

      if ($userInfoContainer.length == 0) {
        return;
      }

      var userInfoContainerHeight = $userInfoContainer.outerHeight(true);

      var $rankingInfoContainer = angular.element('.ranking-info').parent();
      var rankingInfoContainerHeight = $rankingInfoContainer.outerHeight(true);

      var $streamingInfoContainer = angular.element('.streaming-info').parent();

      var streamingInfoContainerHeight = $streamingInfoContainer.outerHeight(true);
      var $streamingBox = angular.element('.streaming-info .infoBox');

      var windowHeight = window.height();

      var currentOffset = windowHeight - userInfoContainerHeight - rankingInfoContainerHeight - streamingInfoContainerHeight;

      var newHeight = $streamingBox.height() + currentOffset;

      if (newHeight < 156) {
        // We cannot go below 156
        newHeight = 156;
      }

      $streamingBox.height(newHeight);
    };

    $scope.gridScroll();

    globalService.niceScroll('.streaming-info .nano');
    globalService.niceScrollUpdate('.streaming-info .nano');

    // Once the currentUser is loaded then, DOM content might refresh
    $rootScope.$watch('currentUser', $rootScope.$broadcast.bind($rootScope, 'windowResize'));

    $scope.$on('windowResize', $scope.gridScroll.bind(this));

    globalService.angularServices.$timeout(function () {
      $rootScope.$broadcast('windowResize');
    }, 900);

  }
]);
