'use strict';

/* Services */

angular.module('game').value('version', '0.1')
  .factory('globalService', ['apiCall', 'angularServices', 'gameConstants', 'i18nService', 'userService', 'logService', 'localStorageService', 'tenant', '$route',
    function (apiCall, angularServices, gameConstants, i18nService, userService, logService, localStorageService, tenant, $route) {

      var contentURL = gameConstants.contentURL;
      var $location = angularServices.$location;
      var $timeout = angularServices.$timeout;
      var $window = angularServices.$window;
      var $rootScope = angularServices.$rootScope;

      if (!Array.prototype.indexOf) {
        Array.prototype.indexOf = function (searchElement /*, fromIndex */) {
          'use strict';
          if (this == null) {
            throw new TypeError();
          }
          var n, k, t = Object(this),
            len = t.length >>> 0;
          if (len === 0) {
            return -1;
          }
          n = 0;
          if (arguments.length > 1) {
            n = Number(arguments[1]);
            if (n != 0 && n != Infinity && n != -Infinity) {
              n = (n > 0 || -1) * Math.floor(Math.abs(n));
            }
          }
          if (n >= len) {
            return -1;
          }
          for (k = n >= 0 ? n : Math.max(len - Math.abs(n), 0); k < len; k++) {
            if (k in t && t[k] === searchElement) {
              return k;
            }
          }
          return -1;
        };
      }
       // String.startsWith pollyfill for IE
      if (!String.prototype.startsWith) {
        String.prototype.startsWith = function(searchString, position) {
          position = position || 0;
          return this.indexOf(searchString, position) === position;
        };
      }

      var _niceScrollPool = {};

      var fullscreenState = false;

      var services = {
        // Facade of general services
        angularServices: angularServices,
        gameConstants: gameConstants,
        i18nService: i18nService,
        userService: userService,
        // Custom services
        /**
         * Logs out the user from the system
         */
        logout: function (redirect) {
          if (!redirect) {
            redirect = '/';
          }

          apiCall.get("logout", {}, {
            handleErrors: {
              404: true /* @todo 404 means it logged out correctly */
            },
          }).then(function () {
            $window.close();
            $location.path(redirect);
            $route.reload();
            //$window.location.reload();
          }, function (reason) {

            if (reason.status == 404) {
              // @todo this 404 means it logged out correctly
              $window.close();
              $location.path(redirect);
              $route.reload();
              //$window.location.reload();
            }
          })
        },

        /**
         * Performs the login to the backend
         * @param username
         * @param password
         * @returns Promise
         */
        login: function (username, password) {

          return apiCall.post('login', {
            j_username: username,
            j_password: password
          }, {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
          }, {
            handleErrors: {
              403: true
            },
            handleAsForm: true
          });
        },

        /**
         * Returns the user information if it can be obtained
         * @returns {*}
         */
        loggedIn: function () {
          return apiCall.get("api/1/user/info", {}, {
            handleErrors: {
              403: true /* @todo 403 means it's not logged in */
            },
          }).then(function (response) {
            return response.data;
          }, function () {
            return false;
          });
        },

        /**
         * Asks the backend to send the user instructions to recover his/her password
         * @param username
         * @returns Promise
         */
        recoverPassword: function (username) {

          return apiCall.post('api/1/pub/account/reset_password/init', username, {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'text/plain'
          }, {
            handleErrors: {
              400: true,
            },
            handleAsForm: false
          });
        },

        /**
         * Asks the backend to set the user a new password
         * @param password
         * @param token
         * @returns Promise
         */
        setNewPassword: function (password, token) {

          return apiCall.post('api/1/pub/account/reset_password/finish', {
            newPassword: password,
            key: token
          }, {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'text/plain'
          }, {
            handleErrors: {
              400: true,
              500: true,
            },
            handleAsForm: false
          });
        },

        /**
         * Sign up a new user account
         * @param password
         * @param token
         * @returns Promise
         */
        signUp: function (signUpModel) {

          return apiCall.post('api/1/pub/account/signup', {
            nickname: signUpModel.nickname,
            surname: signUpModel.surname,
            email: signUpModel.email,
            password: signUpModel.password1,
            employeeId: signUpModel.employeeId
          }, {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'text/plain'
          }, {
            handleErrors: {
              400: true,
              500: true
            },
            handleAsForm: false
          });
        },

        /**
         * Asks the backend to activate a user account
         * @param activationToken
         * @returns Promise
         */
        activateAccount: function (token) {

          return apiCall.post('api/1/pub/account/activation', {
            activationKey: token
          }, {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'text/plain'
          }, {
            handleErrors: {
              400: true,
              500: true
            },
            handleAsForm: false
          });
        },

        /**
         * Applies the nice scrolling style to an element
         * @param element selector
         */
        niceScroll: function (element, delay) {

          if (delay === undefined) {
            delay = true;
          }

          if (_niceScrollPool[element] === undefined) {
            var setup = {
              iOSNativeScrolling: 1,
              sliderMaxHeight: 100
            };

            var $element;
            $element = angular.element(element);

            var createNiceScroll = function() {
              services.niceScrollRemove(element);
              $element.nanoScroller(setup);

              delete _niceScrollPool[element];
            };

            if (delay) {
              _niceScrollPool[element] = $timeout(createNiceScroll, 0);
            } else {
              createNiceScroll.bind(this).call(arguments);
            }
          }

        },

        /**
         * Updates the nice scrolling style when content or position have changed
         * @param element selector
         */
        niceScrollUpdate: function (element) {
          var $element = angular.element(element);
          $element.nanoScroller();
        },

        /**
         * Removes the nice scrolling style
         * @param element selector
         */
        niceScrollRemove: function (element) {
          var $element = angular.element(element);
          $element.nanoScroller({
            destroy: true
          });
        },

        /**
         * toggle fullscreen mode
         * Gets the services full screen mode state and toggle it,
         * also emits a global event of the toggle
         */
        toggleFullscreen: function (saveToLocalStorage) {
          var localStorageKey = 'fullscreen_' + tenant;
          if (!fullscreenState) {
            fullscreenState = !fullscreenState;
            if (saveToLocalStorage) {
              localStorageService.set(localStorageKey, true);
            }

            this.applyFullScreenCssClasses();

            //Request browser fullscreen API
            if (!Utils.isIE()) {
              var el = document.documentElement;
              if(el.requestFullScreen) {
                el.requestFullScreen();
              } else if(el.mozRequestFullScreen) {
                el.mozRequestFullScreen();
              } else if(el.webkitRequestFullscreen) {
                el.webkitRequestFullscreen();
              } else if (el.msRequestFullscreen) {
                el.msRequestFullscreen();
              }
            }

            logService.info('Fullscreen activated');
          }
          else {
            fullscreenState = !fullscreenState;
            if (saveToLocalStorage) {
              localStorageService.set(localStorageKey, false);
            }

            this.removeFullScreenCssClasses();

            // if (!Utils.isIE()) {
            // Exit browser fullscreen API
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.msExitFullscreen) {
              document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
              document.webkitCancelFullScreen();
            }

            logService.info('Fullscreen deactivated');
          }
          $rootScope.$emit("fullscreenChanged", fullscreenState);

          // This is to support height calculations on other components (timer bar)
          $timeout(function () {
            $rootScope.$broadcast('windowResize');
          }, 1000);
        },
        applyFullScreenCssClasses: function () {
          angular.element('html').addClass('fullscreen');
          angular.element('.header').addClass("fullscreen");
          angular.element('.sidebar').addClass("fullscreen");
          angular.element('#footer').addClass("fullscreen");
          angular.element('.mobile-menu').addClass("fullscreen");
          angular.element('#character-menu').addClass("fullscreen");
          angular.element('.view-wrapper').addClass("fullscreen");
          angular.element('.view-port-content').addClass("fullscreen");
          angular.element('#videoWrapper').addClass("fullscreen");
          angular.element('#decalogueAnswer').addClass("fullscreen");
        },
        removeFullScreenCssClasses: function () {
          angular.element('html').removeClass('fullscreen');
          angular.element('.header').removeClass("fullscreen");
          angular.element('.sidebar').removeClass("fullscreen");
          angular.element('#footer').removeClass("fullscreen");
          angular.element('.mobile-menu').removeClass("fullscreen");
          angular.element('#character-menu').removeClass("fullscreen");
          angular.element('.view-wrapper').removeClass("fullscreen");
          angular.element('.view-port-content').removeClass("fullscreen");
          angular.element('#videoWrapper').removeClass("fullscreen");
          angular.element('#decalogueAnswer').removeClass("fullscreen");
        },
        isFullscreenEnabled: function () {
          var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
          return !!fullscreenElement;
        },
        getFullscreenState: function() {
          return fullscreenState;
        },
        getFullscreenLocalStorageSetting: function () {
          var localStorageKey = 'fullscreen_' + tenant;
          return localStorageService.get(localStorageKey);
        }
      };

      return services;
    }
  ])
;
