'use strict';

/* Video Controller */
angular.module('game').controller('introVideoCtrl', ['gameSettings', '$scope', 'introServices',
  function (gameSettings, $scope, introServices) {

    $scope.config = gameSettings;

    /* REFRESH COUNTDOWN FUNCTION. IT WILL STOP AT 0,0,0 AND MAKE ANOTHER PETITION */
    introServices.refreshDate($scope);

  }
]);
