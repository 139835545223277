'use strict';

angular.module('game').controller('userInfoCtrl', ['$scope', '$rootScope', '$location', 'globalService', 'gameSettings',
  function ($scope, $rootScope, $location, globalService, gameSettings) {

    $scope.userName = '';
    $scope.reports = [];

    globalService.userService.getUser().then(function (user) {
      $scope.currentUser = user;
      $scope.userName = $scope.currentUser.nameFront;

      globalService.i18nService.getLocale(false, user.language).then(function(i18n) {
        $rootScope.locale = i18n;
      });

      if (user.roles.indexOf("ROLE_ADMIN") !== -1) {
        $scope.reports.push({name: "User summary", link: globalService.gameConstants.petitionURL + "api/1/admin/report/user_summary"});
        // $scope.reports.push({name: "User summary certification", link: globalService.gameConstants.petitionURL + "api/1/admin/report/user_summary_certification"});
        $scope.reports.push({name: "User interactions", link: globalService.gameConstants.petitionURL + "api/1/admin/report/user_interactions"});
      } else if (user.roles.indexOf("ROLE_REPORT_CREW") !== -1) {
        $scope.reports.push({name: "User summary", link: globalService.gameConstants.petitionURL + "api/1/report/user_summary"});
        // $scope.reports.push({name: "User summary certification", link: globalService.gameConstants.petitionURL + "api/1/report/user_summary_certification"});
        $scope.reports.push({name: "User interactions", link: globalService.gameConstants.petitionURL + "api/1/report/user_interactions"});
      }
    });

    if (gameSettings.settings.extraLinks) {
      $scope.extraLinks = gameSettings.settings.extraLinks;
    } else {
      $scope.extraLinks = [];
    }

    $scope.$on("pointsUpdate", function ($event) {
      console.log("Updating user points");
      globalService.userService.getUser(true).then(function (user) {
        $scope.currentUser = user;
        $scope.userName = $scope.currentUser.nameFront;
      });
    });

    $scope.globalResults = function() {
      $location.path('/results');
    };
  }
]);
