'use strict';

angular.module('game').factory('introServices',
  ['gameSettings', 'apiCall', 'angularServices', 'globalService', 'playerService',
  function (gameSettings, apiCall, angularServices, globalService, playerService) {

    var $interval = angularServices.$interval,
        $location = angularServices.$location,
        mediaURL = globalService.gameConstants.mediaURL,
        i18nService = globalService.i18nService;

    return {
      positioned: false,

      refreshDate: function ($scope) {

        var self = this;
        var intervalPromise;
        var countdown = angular.element(".playButton.intro"),
          date, days, hours, seconds, current_date = new Date().getTime();

        var repeatCounter = 1;
        var lastCounter = 0;
        var positioned = false;
        var videoPlayer = playerService.create("introElement");

        var locale;
        i18nService.getLocale().then(function(decalogue) {
          locale = decalogue;
        });

        if ($scope) {
          $scope.$on("$destroy", function () {
            $interval.cancel(intervalPromise);
          });

          videoPlayer.attachToScope($scope);
        }

        function constructDate(target_date, data) {

          current_date = new Date().getTime();
          var seconds_left = (target_date - current_date) / 1000;
          var days_left = parseInt(seconds_left / 86400);
          seconds_left = seconds_left % 86400;
          var hours_left = parseInt(seconds_left / 3600);
          seconds_left = seconds_left % 3600;

          var minutes = parseInt(seconds_left / 60);
          var seconds = parseInt(seconds_left % 60);

          return "<span>" + data.countdownI18n + " " + days_left + " " + locale['front.terms.days'] + ", <strong style='font-weight:500;'>" + (hours_left) + " " + locale['front.terms.hours'] + ", " + minutes + " " + locale['front.terms.minutes'] + " " + locale['front.terms.and'] + " </span> <span class='seconds'>" + seconds + " " + locale['front.terms.seconds'] + "</strong></span>";

        };

        function setAnswerEvents(e, data) {

          if (parseInt(e.position) == data.enterResource.beginSeconds + data.enterResource.durationSeconds && !positioned) {

            positioned = true;

             if (data.waitResource) {
              videoPlayer.seek(data.waitResource.beginSeconds);
            }

            data.options.forEach(function (element) {

              if (element != null) {
                var temp = angular.element(".answer:not(.set):first");

                temp.click(function () {
                  self.positioned = true;
                  angular.element(".answer").removeClass("set").hide();
                  videoPlayer.seek(element.feedbackResource.beginSeconds);

                  videoPlayer.onTime(function (e) {
                    if (repeatCounter != lastCounter && parseInt(e.position) == element.feedbackResource.beginSeconds + element.feedbackResource.durationSeconds) {
                      lastCounter = repeatCounter;
                      if (element.feedbackResource.bucle) {
                        videoPlayer.seek(element.feedbackResource.beginSeconds);
                      } else {
                        angular.element("#repeatImg").css("display", "block").css("z-index", 2);
                        self.positioned = true;
                        videoPlayer.pause();
                      }
                    }
                  });
                });

                temp.html(element.nameI18n)
                temp.addClass("set");
              };

            });

            angular.element(".answer").show();
          }

          if (data.waitResource && data.waitResource.bucle && parseInt(e.position) == data.waitResource.beginSeconds + data.waitResource.durationSeconds && !self.positioned)
            videoPlayer.seek(data.waitResource.beginSeconds);

          if (data.enterResource && parseInt(e.position) == data.enterResource.beginSeconds + data.enterResource.durationSeconds && positioned && data.enterResource.bucle) {
            videoPlayer.seek(data.enterResource.beginSeconds);
          }

        };

        apiCall.get('api/1/pub/intro').then(function (response) {
          var data = response.data;
          if (data.enterResource != null) {

            angular.element("#repeatImg").click(function () {
              repeatCounter = repeatCounter + 1;
              self.positioned = false;
              positioned = false;
              videoPlayer.seek(0).play();
              angular.element("#repeatImg").css("display", "none").css("z-index", -1);
            });

            self.initializeIntroPlayer(videoPlayer, mediaURL + data.enterResource.url);

            videoPlayer.setCurrentCaptions(1);
            if (videoPlayer.isPlaying()) {
              videoPlayer.seek(data.enterResource.beginSeconds);
            }

            videoPlayer.onTime(function (e) {
              setAnswerEvents(e, data);
            });

            var target_date = data.timestamp != null ? data.timestamp : null;

            // If there's a target_date for this "action" (check if it makes sense for the intro service)
            if (target_date != null) {

              if (target_date > current_date) {
                countdown.addClass("buttonDisabled");
              }

              if (intervalPromise != null) {
                $interval.cancel(intervalPromise);
              }
              // This will prevent the user to run the challenge before a "target_date"
              intervalPromise = $interval(function () {
                if (angular.element(".playButton.intro")[0] == null) {
                  $interval.cancel(intervalPromise);
                } else {
                  // console.log(data.enterI18n);
                  // console.log(seconds);
                  if (data.timestamp < new Date().getTime()) {
                    $interval.cancel(intervalPromise);
                    countdown.css("display", "block");
                    countdown.removeClass("buttonDisabled");
                    self.toggleClassInterval(".playButton", "blink", 1500);
                    countdown.html(data.enterI18n);
                  } else {
                    countdown.css("display", "block");
                    countdown.html(constructDate(target_date, data));
                  }
                }
              }, 1000);
            } else {
              self.toggleClassInterval(".playButton", "blink", 800);
              countdown.css("display", "block");
              countdown.html(data.enterI18n);
            }
          } else {
            countdown.css("display", "block");
            countdown.removeClass("buttonDisabled");
            self.toggleClassInterval(".playButton", "blink", 800);
            countdown.html(data.enterI18n);
          }
        })

      },

      initializeIntroPlayer: function (player, url) {
        var self = this;
        var isVideoResponsive = false;

        var setup = {
          'file': url,
          'mediaid': url,
          'autostart': !Utils.isSafari() && !Utils.isChrome() && !Utils.isMobileOrTablet() && gameSettings.video.autostart,
          'controls': true,
          'tracks': [{
            'file': url + ".vtt",
            'kind': "captions",
            "default": true
          }],
          'captions': gameSettings.settings.videoPlayer.settings.captions,
          events: {
            onReady: function() {
              if (!isVideoResponsive && gameSettings.intro.videoResponsive) {
                isVideoResponsive = true;
                self.makeVideoResponsive(player, 'introElement');
              }

              if (!gameSettings.video.mute) {
                player.setVolume(80);
              }
            },
          }
        };

        // Initialize player
        window.player = player.setup(setup);
      },

      toggleClassInterval: function (element, classN, timer) {
        var promise = $interval(function () {
          var a = angular.element(element);
          if (a.hasClass(classN))
            a.removeClass(classN);
          else
            a.addClass(classN);
        }, timer);
        return promise;
      },

      makeVideoResponsive: function(player, name) {

        var selector = '#' + name;

        var $video =  angular.element(selector + '_video');

        // 590 x 332 --> Video content size
        var min_w = 590; // minimum video width allowed
        var $viewport_container = $video.closest('#introWrapper');
        var $viewport = $video.closest('.b-video-wrapper');

        // original video dimensions
        var vid_w_orig = parseInt($video.width());
        var vid_h_orig = parseInt($video.height());

        // var $viewport = $video.closest(selector);
        var $window = angular.element(window);

        $window.resize(function () { resizeToCover(); });
        $window.trigger('resize');

        function resizeToCover() {

          // use largest scale factor of horizontal/vertical
          var viewport_width = $viewport_container.outerWidth();
          var viewport_height = $viewport_container.outerHeight();

          var scale_h = viewport_width / vid_w_orig;
          var scale_v = viewport_height / vid_h_orig;

          var scale = scale_h > scale_v ? scale_h : scale_v;

          if (viewport_width < min_w) {
            scale = viewport_width / min_w;
          } else {

            // don't allow scaled width < minimum video width
            if (scale * vid_w_orig < min_w) {
              scale = min_w / vid_w_orig;
            }
          }

          // now scale the video
          player.resize((scale * vid_w_orig) + 'px', (scale * vid_h_orig) + 'px');

          // and center it by scrolling the video viewport
          $viewport.css({
            left: -1 * ($video.width() - viewport_width) / 2,
          });

        }
      }
    }

  }
]);
