'use strict';

/* Directives */

angular.module('game')
.directive('appVersion', ['version', function (version) {
  return function (scope, elm, attrs) {
    elm.text(version);
  };
}])
.directive('windowResize', ['$window', function ($window) {
  return function(scope, element, attr) {
    var $$window = angular.element($window);
    $$window.on('resize', function() {
      var args = {
        width: $$window.width(),
        height: $$window.height(),
        outerHeight: $$window.outerHeight(true),
        outerWidth: $$window.outerWidth(true),
      };
      scope.$broadcast('windowResize', args);
    })
  };
}])
.directive('browserDetect', ['$window', function ($window) {
  return function(scope, element, attr) {
    var md = new MobileDetect($window.navigator.userAgent);
    md.phone() && element.addClass('is-mobile');
    md.is('iPhone') && element.addClass('is-mobile-iphone');
    md.tablet() && element.addClass('is-tablet');
    md.is('iPad') && element.addClass('is-tablet-ipad');

    var userAgent = navigator.userAgent.toLowerCase();
    var isFirefox = userAgent.indexOf('firefox');
    if (isFirefox >= 0) {
      var firefox = userAgent.substring(isFirefox);
      var version = Number(firefox.substring(firefox.indexOf('firefox/') + "firefox/".length));
      element.addClass('firefox').addClass(version >= 40 ? 'gt40' : 'lt40');
    }
  };
}])
.directive('gameHref', ['$location', function($location) {
  return {
    restrict: 'A',
    scope: {
    },
    link: function(scope, element, attrs) {

      scope.$watch(attrs.gameHref, function() {
        element.attr('href', attrs.gameHref);
        scope.href = attrs.gameHref;
      });

      element.click(function() {
        $location.path(scope.href);
        return false;
      })
    }
  };
}])
.directive('elemReady', function( $parse ) {
  return {
    restrict: 'A',
    link: function( $scope, elem, attrs ) {
      elem.ready(function(){
        $scope.$evalAsync(function(){
          var func = $parse(attrs.elemReady);
          func($scope);
        })
      })
    }
  }
})
.directive('domUpdate', function( $parse ) {
  return {
    restrict: 'A',
    link: function($scope, $elem, attrs) {
      $scope.$watch(
        function() {
          return $elem[0].childNodes.length;
        },
        function(newLength, oldLength) {
          if (newLength !== oldLength) {
            $parse(attrs.domUpdate)($scope);
          }
        }
      );
    }
  }
})
;
