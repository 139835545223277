'use strict';

/* User Service */

angular.module('game').value('version', '0.1')
  .factory('userService', ['$q', 'apiCall', '$rootScope', function ($q, apiCall, $rootScope) {
    var service = {};

    /**
     * Retrieves the locale information
     *
     * @param reload the user
     * @returns {*}
     */
    service.getUser = function (reload) {
      if (reload == undefined) {
        reload = false;
      }

      if ($rootScope.currentUser == null || reload) {
        // var self = this;
        var q = $q.defer();

        apiCall.get('api/1/user/info').then(function (response) {
          $rootScope.currentUser = response.data;
          q.resolve($rootScope.currentUser);
        });
        return q.promise;
      }
      return $q.when($rootScope.currentUser);
    };

    return service;
  }]);
