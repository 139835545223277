'use strict';
/* VIDEO CONTROLLER */

angular.module('game').controller('challengeCtrl', [
  '$scope',
  '$rootScope',
  '$document',
  'gameSettings',
  'videoServices',
  'audioService',
  'resultsServices',
  'globalService',
  'playerService',
  'logService',
  'tenant',
  'localStorageService',
  function ($scope, $rootScope, $document, gameSettings, videoServices, audioService, resultsServices, globalService, playerService, logService, tenant, localStorageService) {
    var timeout1, timeout2;

    var controller = this;
    var $route = globalService.angularServices.$route;
    var $routeParams = globalService.angularServices.$routeParams;
    var $location = globalService.angularServices.$location;
    var $interval = globalService.angularServices.$interval;
    var $timeout = globalService.angularServices.$timeout;

    var contentURL = globalService.gameConstants.contentURL;

    // Players attached to this scope
    var videoPlayer = null;
    var soundEffectPlayer = null;

    // This state will be used by the videoService to control if the play/pause actions are taken by the
    // business (the real game) or if it's being done by the user somehow (i.e. via console)
    var videoPlayerState = { volume: 100 };

    $scope.range = Utils.range;

    var updatedPoints = false;

    var currentChallenge = $routeParams.id,
      currentTime = 0,
      timerBar;

    function init() {

      $scope.$on("$destroy", function () {
        $interval.cancel(timerBar);
        $timeout.cancel(timeout1);
        $timeout.cancel(timeout2);
      });

      globalService.loggedIn().then(function (data) {
        if (data) {
          var localStorageRefererUrlKey = "login_referer_url_" + tenant;
          var refererUrl = localStorageService.get(localStorageRefererUrlKey);
          if (refererUrl) {
            localStorageService.remove(localStorageRefererUrlKey);
            $location.path(refererUrl);
          }
        }
      });


      $scope.$emit("pageChange", {page: "challenge"});

      $scope.gameSettings = gameSettings;

      $scope.videoAutostart = gameSettings.video.autostart && !Utils.isMobileOrTablet() && !Utils.isSafari() && !Utils.isChrome() ;

      $scope.showChallengePreview = false;

      $scope.subtitlesState = false;
      $scope.subtitlesBtnOn = contentURL + 'images/common/v2/icons/subtitles-button-active.png';
      $scope.subtitlesBtnOff = contentURL + 'images/common/v2/icons/subtitles-button-disable.png';
      $scope.subtitlesBtnPath = $scope.subtitlesBtnOff;

      $scope.playPauseBtnPause = contentURL + 'images/common/v2/icons/pausing-35x35.png';
      $scope.playPauseBtnPlay = contentURL + 'images/common/v2/icons/resuming-35x35.png';
      $scope.playPauseBtnPath = $scope.playPauseBtnPause;

      $scope.playPauseButtonTimer = null;

      $scope.showStatistics = true;

      $scope.finished = false;

      $scope.fullscreenState = false;
      $scope.showFullscreenBtn = gameSettings.video.fullscreen.active && !Utils.isMobile();
      $scope.fullScreenBtnOn = contentURL + 'images/common/v2/icons/fullscreen-btn-on.png';
      $scope.fullScreenBtnOff = contentURL + 'images/common/v2/icons/fullscreen-btn-off.png';
      $scope.fullScreenBtnPath = $scope.fullScreenBtnOn;

      $rootScope.$on("fullscreenChanged", function(event, state) {
        $scope.fullscreenState = state;
        logService.info("Fullscreen state changed: ", $scope.fullscreenState);
      });

      $document.on('fullscreenchange webkitfullscreenchange mozfullscreenchange MSFullscreenChange', function(){
        if (globalService.isFullscreenEnabled()) {
          logService.info("Browser Fullscreen API CHANGED to true");
          //$scope.fullscreenState = true;
        } else {
          logService.info("Browser Fullscreen API CHANGED to false");
          //$scope.fullscreenState = false;
          // If browser API fullscreen state is false and scope fullscreen state is true then toggle fullscreen
          if ($scope.fullscreenState) {
            globalService.toggleFullscreen(true);
          }
        }
        $scope.fullScreenBtnPath = $scope.fullscreenState ? $scope.fullScreenBtnOff : $scope.fullScreenBtnOn;
        //createTimerBarSteps($scope);
      });

      $document.on('onfullscreenerror onwebkitfullscreenerror onmozfullscreenerror onmsfullscreenerror onwebkitfullscreenerror', function() {
        logService.info("FULLSCREEN ERROR");
        if(globalService.getFullscreenState()) {
          globalService.toggleFullscreen(true);
        }
        //createTimerBarSteps($scope);
      });

      $scope.isHidden = true;
      $scope.isLoading = true;
      $scope.isReady = false;
      $scope.currentPoints = 0;
      $scope.routeId = currentChallenge;
      $scope.contentURL = contentURL;
      $scope.currentSuccess = false;

      createTimerBarSteps($scope);
      $scope.$on('windowResize', createTimerBarSteps.bind(this, $scope));

      angular.element(".answer").hide();
      angular.element("#lastScreen").addClass('hidden');

      $scope.reloadChallenge = function () {
        $scope.isReady = false;
        $scope.isHidden = true;
        $scope.isLoading = true;
        $scope.finished = false;
        angular.element(".videoInteraction").removeClass('finished');
        $route.reload();
      };

      $scope.gotoStatistics = function () {
        if (globalService.getFullscreenState()) {
          globalService.toggleFullscreen(false);
        }
      };

      /* SHOW ANSWERS AFTER CLICK ON THE "IM READY" BUTTON ON EMAIL TYPE */
      $scope.showAnswers = function () {
        $scope.isReady = true;
        videoServices.fadeChain(controller.challengeData.steps[controller.challengeData.firstChallengeStepId].options);
        var requestData = {
          "challengeId": parseInt(controller.challengeData.id),
          "challengeStepId": parseInt($scope.currentStep)
        };
        videoServices.postChallengeStartAnswer(requestData).then(function () {
          bindAnswerEvents();
          animateBar((controller.challengeData.steps[controller.challengeData.firstChallengeStepId].maxSeconds * 1000));
        }).catch(function () {
          logService.error('Error starting the challenge step answer');
        });
      };

      loadChallenge();

      $rootScope.$on("languageChanged", function () {
        loadChallenge();
      });

    }

    /**
     * Creates the steps of the timer bar
     */
    function createTimerBarSteps($scope) {
      $scope.barHeight = angular.element(".timer-bar span").outerHeight(true) || 8;

      var emailScreenHeight = angular.element(".videoInteraction #emailScreen").outerHeight() || 300;
      var videoHeight = angular.element(".videoInteraction #element").outerHeight();

      $scope.timerBarLines = Math.ceil(Math.max(emailScreenHeight, videoHeight) / $scope.barHeight);
    }

    function updateDecalogue() {

      if (!updatedPoints) {
        updatedPoints = true;

        videoServices.getDecalogueStatus().then(function (response) {
          var data = response.data;
          $scope.menuImg = contentURL + 'images/common/icons/icon-menu-27x27.png';
          $scope.circleImg = contentURL + 'images/common/main/circle.png';
          $scope.mediaURL = contentURL;
          $scope.position = data.position;
          $scope.points = data.points;

          $scope.decalogues = data.decalogues;
          $scope.status = data.status;

          $scope.finished = true;
          // If current challenge total points is zero then don`t show statistics
          if ($scope.showStatistics) {
            resultsServices.startAnimation($scope.status);
            videoFinished();
          } else {
            // Show video preview
            if ($scope.gameSettings.settings.showPreviewOnFinishWithZeroPoints)
              $scope.showChallengePreview = true;
          }
        }).catch(function () {
          $location.path("#/home");
        });
      }
    }

    function _stopSoundEffectPlayer() {
      if (soundEffectPlayer) {
        soundEffectPlayer.stop();
      }
    }

    function _dealsWithPrebuffering(step) {
      // Checks if the player shoulds prebuffer the video or not
      var challengeShouldBePreBuffered = (step.externalId.indexOf('BUFFERING_STEP') !== -1);
      if (challengeShouldBePreBuffered) {
        videoPlayerState.volume = 0;
        videoPlayerState.preloadBuffer = true;
        videoPlayerState.ending = step.questionResource.beginSeconds;
        $scope.ispreloadingBuffer = true;
      }
    }

    function _addChallengeClassBasedOnChallengeType(challengeType) {
      if (challengeType !== "E") {
        angular.element('#videoWrapper').addClass('video-challenge')
      } else {
        angular.element('#videoWrapper').addClass('email-challenge')
      }
    }

    function _dealsWithPlayerSubtitlesSettings() {
      // Subtitles initialization
      if (gameSettings.video.subtitles.active) {
        if (gameSettings.video.subtitles.autostart === true) {
          $scope.subtitlesState = true;
          $scope.subtitlesBtnPath = $scope.subtitlesBtnOn;
          videoPlayer.setCurrentCaptions(1);
          logService.info('Subtitles active');
        }
        else {
          videoPlayer.onReady(function () {
            if ((typeof videoPlayer.getCurrentCaptions() !== 'number') || videoPlayer.getCurrentCaptions() === 0) {
              $scope.subtitlesState = false;
              $scope.subtitlesBtnPath = $scope.subtitlesBtnOff;
              videoPlayer.setCurrentCaptions(0);
              logService.info('Subtitles disable');
            }
            else {
              $scope.subtitlesState = true;
              $scope.subtitlesBtnPath = $scope.subtitlesBtnOn;
              videoPlayer.setCurrentCaptions(1);
              logService.info('Subtitles active');
            }
          });
        }

        // Subtitles button events
        angular.element('#subtitlesButton').on("click", function(e) {
          e.stopPropagation();
          if ($scope.subtitlesState) {
            $scope.subtitlesState = false;
            $scope.subtitlesBtnPath = $scope.subtitlesBtnOff;
            $scope.$apply();
            videoPlayer.setCurrentCaptions(0);
            logService.info('Subtitles desactivated');
          }
          else {
            $scope.subtitlesState = true;
            $scope.subtitlesBtnPath = $scope.subtitlesBtnOn;
            $scope.$apply();
            videoPlayer.setCurrentCaptions(1);
            logService.info('Subtitles activated');
          }
        });
      }
    }

    function _dealsWithFullscreenSettings(){
      if (gameSettings.video.fullscreen.active && !Utils.isMobile()) {
        $scope.toggleFullScreen = function(saveToLocalStorage) {
          globalService.toggleFullscreen(saveToLocalStorage)
        }

        if (!globalService.getFullscreenState() && globalService.getFullscreenLocalStorageSetting()) {
          globalService.toggleFullscreen(false);
        }
        if (globalService.getFullscreenState()) {
          globalService.applyFullScreenCssClasses();
          $scope.fullscreenState = true;
        }
      }
    }

    function loadChallenge() {

      videoServices.getChallengeData(currentChallenge).then(function (response) {
        var json = response.data;
        globalService.userService.getUser().then(function (user) {
          var time = json.steps[json.firstChallengeStepId].maxSeconds !== undefined ? json.steps[json.firstChallengeStepId].maxSeconds : 10;

          $scope.challengeType = json.type;
          controller.challengeData = json;

          if (json.soundResource !== undefined) {
            audioService.setVolumeMax(gameSettings.globalAudio.challenge);
            audioService.play(json.soundResource.url, json.soundResource.bucle);
          } else {
            audioService.remove();
          }

          $scope.seconds = time;
          $scope.avatarI18n = json.avatarI18n;
          $scope.lifes = json.maxAttempts;
          $scope.avatarurl = contentURL + 'images/challenges/' + json.avatarI18n;
          $scope.customerName = json.project.customer.name1I18n;
          $scope.challengeName = json.name1I18n;
          $scope.challengeName_2 = json.name2I18n;
          $scope.currentStep = json.firstChallengeStepId;

          videoServices.registerSteps(json.steps);
          videoServices.registerNext($scope.currentStep);

          if (json.userMaxPoints !== undefined) {
            $scope.maxPoints = json.userMaxPoints;
          }

          var initialStep = json.steps[$scope.currentStep];

          if (json.type === "E") {
            $scope.emailURL = contentURL + initialStep.questionResource.url;
            globalService.niceScroll('#emailScreen body');
          }

          bindAnswerEvents();
          _stopSoundEffectPlayer();
          _dealsWithPrebuffering(initialStep);
          _addChallengeClassBasedOnChallengeType(json.type);

          videoPlayer = videoServices.initializePlayer(initialStep.introResource.url, videoPlayerState, user.language);
          videoPlayer.attachToScope($scope);

          videoServices.activateDevKeyLogger($scope, videoPlayer);

          _dealsWithPlayerSubtitlesSettings();
          _dealsWithFullscreenSettings();

          $scope.videoClick = function() {
            if ($scope.finished) return;
            var playPauseIndicator = angular.element("#playPauseIndicator");
            if(!Utils.isMobileOrTablet()) {
              if (videoPlayer.isPlaying()) {
                $scope.playPauseBtnPath = $scope.playPauseBtnPlay;
                playPauseIndicator.removeClass('resuming').addClass('pausing');
                videoServices.stopVideo(videoPlayer, videoPlayerState);
                playPauseIndicator.show().delay(200).fadeOut(750);
              } else {
                playPauseIndicator.removeClass('pausing').addClass('resuming');
                if (videoServices.resumeVideo(videoPlayer, videoPlayerState)) {
                  $scope.playPauseBtnPath = $scope.playPauseBtnPause;
                  playPauseIndicator.show().delay(200).fadeOut(750);
                }
              }
            } else {
              if (videoPlayer.isPlaying()) {
                playPauseIndicator.removeClass('resuming').addClass('pausing');
              } else {
                playPauseIndicator.removeClass('pausing').addClass('resuming');
              }
              if (videoPlayerState.canPlay) {
                playPauseIndicator.show();
                $timeout.cancel($scope.playPauseButtonTimer);
                $scope.playPauseButtonTimer = $timeout(function () {
                  playPauseIndicator.hide();
                }, 2500);
              }
            }
          };

          $scope.togglePlayPause = function(always) {
            var playPauseIndicator = angular.element("#playPauseIndicator");
            if (Utils.isMobileOrTablet() || always) {
              if (videoPlayer.isPlaying()) {
                $scope.playPauseBtnPath = $scope.playPauseBtnPlay;
                playPauseIndicator.removeClass('resuming').addClass('pausing');
                videoServices.stopVideo(videoPlayer, videoPlayerState);
                // playPauseIndicator.delay(100).fadeOut(500);
              } else {
                if (videoServices.resumeVideo(videoPlayer, videoPlayerState)) {
                  playPauseIndicator.removeClass('pausing').addClass('resuming');
                  $scope.playPauseBtnPath = $scope.playPauseBtnPause;
                  playPauseIndicator.delay(100).fadeOut(500);
                }
              }
            }
          };

          addEvents(initialStep);

          createTimerBarSteps($scope);

          $scope.isReady = true;
          $scope.activeSession = "introResource";
          $scope.currentStep = json.firstChallengeStepId;

        });

        // RESULTS ADDON

      }).catch(function () {
        $location.path("#/login");
      });

    }

    /**
     * The video finished playing either because the user failed or won
     */
    function videoFinished() {
      $scope.isHidden = false;
      angular.element(".videoInteraction").addClass('finished');
      globalService.niceScroll('#lastScreen');
    }

    /**
     * Makes the player play in loop
     * @param time
     */
    function seekOn(time) {
      var positioned = false;
      var step = $scope.currentStep;
      var session = $scope.activeSession;
      videoPlayer.onTime(function (e) {
        var isCurrentStep = step === $scope.currentStep;
        var isActiveSession = session === $scope.activeSession;

        var isCurrentStepNotPositioned = (parseInt(e.position) !== time && !positioned && isCurrentStep);
        var isCurrentStepPositioned = (parseInt(e.position) < parseInt(time) && isActiveSession && positioned && isCurrentStep);
        //var ispreloadingBuffer = controller.challengeData.steps[step].externalId.indexOf('BUFFERING_STEP') !== -1;
        var ispreloadingBuffer = false; // fix issue with isPreloadingBuffer that try to get non-existsent externalId

        if (!ispreloadingBuffer && (isCurrentStepPositioned || isCurrentStepNotPositioned)) {
          videoPlayer.seek(parseInt(time));
          positioned = true;
        }
      })
    }

    /* CHECK IF THE RESOURCE TYPE IS A VIDEO */
    /*
    Adds listeners for every step phases
     */
    function addEvents(step) {
      if ($scope.challengeType !== "E") {
        addTimeListener(step, "introResource", "questionResource");
        addTimeListener(step, "questionResource", "waitResource");
        addTimeListener(step, "waitResource", "waitResource");
      } else {
        addTimeListener(step, "introResource", "waitResource");
        addTimeListener(step, "waitResource", "waitResource");
      }
    }

    /* SET THE LISTENER TO HANDLE A VIDEO STEP */
    function addTimeListener(step, type, targetStep) {
      videoPlayer.onTime(function (e) {
        var inStepEnd = parseInt(e.position) === parseInt(step[type].beginSeconds) + parseInt(step[type].durationSeconds);
        var isCurrentStepTheLatestOne = inStepEnd && type === $scope.activeSession && step.id === $scope.currentStep
        // If video position is at the end of the step, the active session is the current  and the step id is the current step
        if (isCurrentStepTheLatestOne) {
          var isABucle = step[type].bucle;
          if (!isABucle) {
            videoPlayer.seek(step[targetStep].beginSeconds);

            $scope.activeSession = targetStep;
            if ( type === "questionResource") {
              videoServices.fadeChain(step.options, $scope.highlightMax);
              var requestData = {
                "challengeId": parseInt(controller.challengeData.id),
                "challengeStepId": parseInt($scope.currentStep)
              };
              videoServices.postChallengeStartAnswer(requestData).then(function () {
                bindAnswerEvents();
                if (angular.element(".answer.active").length === 0) {
                  var shouldAnimateBar = (step.maxSeconds !== undefined && step.maxSeconds !== 0);
                  if (shouldAnimateBar) {
                    animateBar(step.maxSeconds * 1000);
                  }
                }
              }).catch(function () {
                logService.error('Error starting the challenge step answer');
              });
            }
            var introResourceIsEmailChallenge = (type === "introResource" && $scope.challengeType === "E");
            if (introResourceIsEmailChallenge) {
              angular.element(".timer-bar span").css("background", $scope.gameSettings.timerBar.colorPrimary);
              $scope.email = contentURL + step['questionResource'].url;
              videoServices.interactionByEmail();
            }
          } else if (step.externalId.indexOf('BUFFERING_STEP') === -1) {
              videoPlayer.seek(step[type].beginSeconds);
          }
        }
      })
    }

    /* ANIMATE TIME BAR WHEN A QUESTION IS LAUNCHED */
    function animateBar(time) {
      $scope.seconds = time / 1000;

      angular.element(".timer-bar span").removeClass("disabled");
      angular.element(".timer-bar span").css('background', $scope.gameSettings.settings.timerBar.colorPrimary);
      var counter = 0;
      var steps = angular.element(".timer-bar span").length;
      currentTime = new Date().getTime();
      timerBar = $interval(function () {
        counter++;
        if ($scope.gameSettings.settings.timerBar.gradient) {
          angular.element(".timer-bar span:not(.disabled)").css('background', Utils.toRGB((steps - counter) * 100 / steps));
        }
        var element = angular.element(".timer-bar span:not(.disabled):first");
        element.css('background', $scope.gameSettings.settings.timerBar.colorSecondary);
        element.addClass("disabled");
      }, (time / steps), steps);
    }

    /* ADD ANSWERS ON CLICK EVENT */
    function bindAnswerEvents() {
      angular.element(".answer").on("click", function () {

        var $this = angular.element(this);
        var element = $this[0];
        if (!($this.hasClass("disabled"))) {

          timeout1 = $timeout(function () {
            $interval.cancel(timerBar);
          }, 250);

          $scope.activeSession = "";

          angular.element("#waittingTag").show();
          angular.element(".answer").addClass("disabled");
          var score = (new Date().getTime() - currentTime) / 1000;
          var ispreloadingBuffer = $scope.ispreloadingBuffer;
          if ((score) > $scope.seconds) {
            score = $scope.seconds;
          }
          if (controller.challengeData.steps[$scope.currentStep].options[element.id] !== undefined && controller.challengeData.steps[$scope.currentStep].options[element.id].feedbackSoundResource !== undefined) {
            soundEffect(contentURL + controller.challengeData.steps[$scope.currentStep].options[element.id].feedbackSoundResource.url);
          }

          var requestData = {
            "challengeId": parseInt(controller.challengeData.id),
            "challengeStepId": parseInt($scope.currentStep),
            "challengeStepOptionId": parseInt(element.id),
            "time": score * 1000
          };

          // Send post challenge option to server
          videoServices.postChallengeAnswer(requestData).then(function (response) {
            var data = response.data;
            $scope.nextChallengeId = data.nextChallengeId;
            if ($scope.nextChallengeId) {
              $scope.nextChallenge = true;
              $scope.nextChallengePath = "#/challenge/" + $scope.nextChallengeId;
            } else {
              // Make next challenge button change to profile button
              $scope.nextChallenge = false;
              $scope.nextChallengePath = "#/results/";
            }


            angular.element("#waittingTag").hide();
            if (data.attempts === 0)
              $scope.currentPoints = data.totalPoints;

            $this.addClass("active");

            if (data.type === 'G') {
              $this.addClass("good disabled");
            }

            if (data.type === 'B') {
              // only lose life when answer is bad
              videoServices.lifeBlink();
              $this.addClass("bad disabled");
              // If the bad answer continues or finish hide the answers and show decalogue point
              if (data.nextChallengeStepId !== $scope.currentStep) {
                angular.element(".answer").slideUp(function () {
                  angular.element(".answer").remove();

                  if (data.feedbackI18n !== undefined) {
                    videoServices.showDecaloguePoint(data.feedbackI18n, true);
                  }
                });
              }
            } else if (data.type === 'R') {
              if (data.nextChallengeStepId === $scope.currentStep) {
                $this.addClass("regular disabled");
              } else {
                angular.element(".answer").slideUp(function () {
                  angular.element(".answer").remove();

                  if (data.feedbackI18n !== undefined) {
                    videoServices.showDecaloguePoint(data.feedbackI18n, true);
                  }
                });
              }
              if (!ispreloadingBuffer) {
                videoServices.floatText(data.points, data.totalPoints);
                $scope.currentPoints = data.totalPoints;
              }
            } else if (data.points !== undefined) {
              angular.element(".answer").slideUp(function () {
                angular.element(".answer").remove();

                if (data.feedbackI18n !== undefined) {
                  videoServices.showDecaloguePoint(data.feedbackI18n, true);
                }
              });
              if (!ispreloadingBuffer) {
                videoServices.floatText(data.points, data.totalPoints);
                $scope.currentPoints = data.totalPoints;
              }

            }

            if (data.decalogues !== undefined) {
              $scope.decalogue = data;
              $scope.challengeFeedback = data.feedbackI18n !== undefined ? data.feedbackI18n : $scope.locale['front.video.default.feedback'];
            }

            // If there is a feedback resource play it
            if (controller.challengeData.steps[$scope.currentStep].options[element.id].feedbackResource !== undefined) {
              angular.element(".button-section").addClass("sectionDisabled");

              var feedbackStep = controller.challengeData.steps[$scope.currentStep].options[element.id].feedbackResource;

              // Move player to feedback resource
              videoPlayer.seek(feedbackStep.beginSeconds);

              var answered = true;
              // On every player update (10 per second)
              videoPlayer.onTime(function (e) {

                if (parseInt(e.position) === parseInt(feedbackStep.beginSeconds) + parseInt(feedbackStep.durationSeconds) && answered) {
                  angular.element(".button-section").removeClass("sectionDisabled");
                  processAnswer(data, element);
                  answered = false;
                }
              })
            } else {
              // If there is no feedback
              processAnswer(data, element);
            }
            if (ispreloadingBuffer) {
              $scope.isLoading = false;
              videoPlayer.setVolume(100);
              $scope.ispreloadingBuffer = false;
            }
          }).catch(function (e) {
            videoPlayer.stop();
          })
        }
      })
    }

    function processAnswer(data, element) {
      if ((data.type === 'B' && data.nextChallengeStepId === $scope.currentStep)
        || (data.type === 'R' && data.nextChallengeStepId === $scope.currentStep)) {
        loseLife(data.attempts, element.id, element, data.type, data.challengeFeedbackI18n, true);
        angular.element(".answer").removeClass("disabled");
        angular.element(".answer.regular").addClass("disabled");
        angular.element(".answer.bad").addClass("disabled");
      } else if (data.type === 'B' && data.nextChallengeStepId !== undefined && data.nextChallengeStepId !== $scope.currentStep) {
        // If its a bad answer that continue to next step
        if (data.attempts < 1) {
          // If was the last attempt (life) then show game over...
          loseLife(data.attempts, element.id, element, data.type, data.challengeFeedbackI18n, true);
        } else {
          // Its a bad answer that continue the path, so lose life without seek in the video and then go with correct answer flow
          loseLife(data.attempts, element.id, element, data.type, data.challengeFeedbackI18n, false);
          correctAnswer(element, data.nextChallengeStepId, data.points, data.totalPoints, data.bestScore, data.challengeFeedbackI18n);
        }

      } else {
        correctAnswer(element, data.nextChallengeStepId, data.points, data.totalPoints, data.bestScore, data.challengeFeedbackI18n);
      }
    }

    /* MAKE THE QUESTION/ANSWER PETITION AND HIDE THE ANSWERS */
    function correctAnswer(answer, next, points, totalPoints, bestScore, finalFeedback) {

      if (controller.challengeData.type === "E") {
        angular.element("#emailScreen").hide();
      }

      // If there is a next step
      if (next !== undefined) {
        if (controller.challengeData.steps[next].maxSeconds !== undefined) {
          $scope.seconds = controller.challengeData.steps[next].maxSeconds;
          $scope.barHeight = 9.5;
        }

        videoServices.registerNext(next);

        var currentStep = controller.challengeData.steps[next];
        $scope.currentStep = next;
        $scope.activeSession = "introResource";

        videoPlayer.seek(currentStep.introResource.beginSeconds);

        addEvents(currentStep);
      } else {
        // If there is not a next step (finished challenge)
        videoServices.showLastPoint(finalFeedback);

        $scope.challengeSucceded = true;
        $rootScope.$broadcast("pointsUpdate");

        $scope.finalFeedback = finalFeedback;

        if (bestScore !== undefined && bestScore) {
          $scope.currentSuccess = true;
        }

        $scope.activeSession = "";
        if (totalPoints === 0) {
          $scope.showStatistics = false;
        }
        if (controller.challengeData.successResource !== undefined) {

          videoPlayer.seek(controller.challengeData.successResource.beginSeconds);
          videoServices.registerNext(parseInt(controller.challengeData.successResource.beginSeconds) + parseInt(controller.challengeData.successResource.durationSeconds));

          videoPlayer.onTime(function (e) {

            if (parseInt(e.position) === parseInt(controller.challengeData.successResource.beginSeconds) + parseInt(controller.challengeData.successResource.durationSeconds)) {
              videoServices.registerNext(null);
              updateDecalogue();
              if (controller.challengeData.successResource.bucle)
                seekOn(controller.challengeData.successResource.beginSeconds);
              else {
                videoServices.stopVideo(videoPlayer, videoPlayerState);
              }
            }

          });

        } else {
          videoServices.stopVideo(videoPlayer, videoPlayerState);
          updateDecalogue();
        }
      }
    }

    /* SHOW LOSE LIFE ANIMATION. IF ACTUAL LIFES == 0 SEND IT TO BAD FINALE */
    function loseLife(current, option_id, currentElement, type, finalFeedback, seek) {
      $scope.activeSession = "waitResource";
      if (current === 0)
        angular.element(".answer").slideUp();

      angular.element(".timer-bar span").removeClass("disabled").css('background', $scope.gameSettings.settings.timerBar.colorSecondary);

      if (current === 0) {
        videoServices.showLastPoint(finalFeedback);
        $scope.challengeSucceded = false;
        $scope.finalFeedback = finalFeedback;

        angular.element(".videoInteraction").addClass('no-timer');
        angular.element(".finishOptions").show();

        angular.element(".main img").css("display", "none");
        $scope.activeSession = "";
        angular.element(".answer").hide();
        angular.element("#emailScreen").hide();

        if (controller.challengeData.failureResource !== undefined) {
          videoPlayer.seek(parseInt(controller.challengeData.failureResource.beginSeconds));

          soundEffect(contentURL + $scope.locale['front.video.failureSound']);

          var failureBehaviour = gameSettings.settings.failureBehaviour;

          videoPlayer.onTime(function (e) {
            if (failureBehaviour.mode === 'showStatisticsAfterTime') {
              if (parseInt(e.position) === parseInt(controller.challengeData.failureResource.beginSeconds) + failureBehaviour.time) {
                updateDecalogue();
              }
            } else if (failureBehaviour.mode === 'showStatisticsAfterFinish') {
              if (parseInt(e.position) === parseInt(controller.challengeData.failureResource.beginSeconds) + parseInt(controller.challengeData.failureResource.durationSeconds)) {
                updateDecalogue();
              }
            }

            if (parseInt(e.position) === parseInt(controller.challengeData.failureResource.beginSeconds) + parseInt(controller.challengeData.failureResource.durationSeconds)) {

              if (controller.challengeData.failureResource.bucle)
                seekOn(controller.challengeData.failureResource.beginSeconds);
              else {
                videoServices.stopVideo(videoPlayer, videoPlayerState);
              }
            }
          })
        } else {
          videoServices.stopVideo(videoPlayer, videoPlayerState);
          updateDecalogue();
        }
      } else {
        var currentStepInfo = controller.challengeData.steps[$scope.currentStep];

        if (controller.challengeData.type !== "E") {
          if (currentStepInfo.questionResource.durationSeconds === 0) {
            if (seek)
              seekOn(currentStepInfo.waitResource.beginSeconds);
          } else {
            if (seek) {
              videoPlayer.seek(currentStepInfo.questionResource.beginSeconds);
              videoPlayer.onTime(function (e) {
                if (parseInt(e) === parseInt(currentStepInfo.questionResource.beginSeconds) + parseInt(currentStepInfo.questionResource.durationSeconds)) {
                  seekOn(currentStepInfo.waitResource.beginSeconds);
                }
              });
            }
          }
        } else {
          if (seek)
            seekOn(currentStepInfo.waitResource.beginSeconds);
          angular.element("#emailScreen").show();
        }
      }
    }

    function soundEffect(url) {
      soundEffectPlayer = playerService.create("soundEffects").setup({
        'file': url,
        'autostart': true,
        'volume': 100
      });
      soundEffectPlayer.play();
      soundEffectPlayer.attachToScope($scope);
    }

    init();
  }
]);
