'use strict';

/* Services */

angular.module('game').value('version', '0.1')
  .factory('timeoutPromise', function ($q, $timeout, $rootScope) {
    var promise = function () {
      var status = $rootScope.locale ? (parseInt($rootScope.locale['front.timeout']) || -1) : -1;
      if (status != -1) {
        var q = $q.defer();
        $timeout(function () {
          q.resolve();
        }, status);
        return q.promise;
      } else {
        return '';
      }
    }
    return promise;
  })
  ;
