'use strict';

/* Error message Directive */

angular.module('game')
.directive('errorMessage', function() {
  return {
    restrict: 'A',
    templateUrl: 'app/partials/error-message/error-message.html',
    scope: {},
    controller: function($scope, $rootScope, i18nService) {

      /**
       * Shows the error popup
       *
       * This is exposed to all the site through scope inheritance
       *
       * @param errorCode locale key or complete safe html message
       * @param raw boolean, default false, tells whether errorCode is a complete message (true) or a locale key (false)
       */
      $rootScope.showError = function(errorCode, raw) {

        if (!raw) {
          if (!errorCode) {
            errorCode = 'front.errorCode.generic';
          }

          i18nService.getLocale().then(function (locale) {
            $scope.error = locale[errorCode];

            if (locale[errorCode]) {
              $scope.errorPopupAPI.open();
            }
          });

        } else {
          $scope.error = errorCode;
          $scope.errorPopupAPI.open();
        }

      };

      // This is the property that will be retrieved from the inner popup directive
      $scope.$watch('errorPopupAPI', function() {
        if ($scope.errorPopupAPI) {

          $rootScope[$scope.popupAttr] = $scope.errorPopupAPI.addClass('error-message-container');

          if ($scope.error) {
            $rootScope.showError();
          }

          $scope.errorPopupAPI.addCallbackOnClose(function() {
            $scope.error = false;
          });
        }
      })
    },
    link: function(scope, element, attrs) {

      scope.element = angular.element(element);
      var errorAttr = attrs['error-message'];

      // This is the attribute that will expose the API to the wrapper controller
      if (!errorAttr) {
        errorAttr = 'errorPopupAPI';
      }
      scope.popupAttr = errorAttr;

    }
  };
});
