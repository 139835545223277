'use strict';

angular.module('game').controller('rankingCtrl', ['$scope', '$rootScope', 'i18nService', '$timeout', 'apiCall', 'contentURL', 'gameSettings', 'rankingService',
  function ($scope, $rootScope, i18nService, $timeout, apiCall, contentURL, gameSettings, rankingService) {

    var searching;

    // Initial text in the search box
    $scope.searchText = '';

    function init() {

      $scope.hasCustomRanking = gameSettings.settings.ranking.hasCustomRanking;
      $scope.hasGlobalRanking = gameSettings.settings.ranking.hasGlobalRanking;
      $scope.hasFriendsRanking = gameSettings.settings.ranking.hasFriendsRanking;

      $scope.contentURL = contentURL;

      i18nService.getLocale().then(function (locale) {
        $scope.findYourFriendLocale = locale['front.layout.findYourFriend'];
        $scope.findAnotherFriendLocale = locale['front.layout.findAnotherFriend'];
        $scope.confirmChallengeQuestionLocale = locale['front.layout.confirmChallengeQuestion']
        $scope.searchFriend = locale['front.layout.findYourFriend'];
        $scope.boxMessage = locale['front.challenge.challengeFriend'];
        $scope.boxMessageStartSearch = locale['front.challenge.challengeFriend'];
      });

      $scope.challengedUser = 1;

      $rootScope.$watch('currentUser', currentUserRelatedTasks.bind(this));

      if ($rootScope.currentUser !== undefined) {
        currentUserRelatedTasks();
      }
    }

    function currentUserRelatedTasks() {

      if ($rootScope.currentUser === undefined) {
        return;
      }

      if ($scope.hasGlobalRanking) {
        rankingService.requestUsersGlobal().then(function(data) {
          $scope.usersGlobal = data;

          angular.forEach($scope.usersGlobal, function(value, index) {
            value.isCurrentUser = value.value.id === $rootScope.currentUser.id;
          });
        });
      }


      if ($scope.hasCustomRanking) {
        rankingService.requestUsersCustomRanking().then(function (data) {
          $scope.usersCustomRanking = data;

          angular.forEach($scope.usersCustomRanking, function(value, index) {
            value.isCurrentUser = value.value.id === $rootScope.currentUser.id;
          });
        });
      }

      if ($scope.hasFriendsRanking) {
        $scope.notify = 0;
        rankingService.getFriendshipRequests().then(function(data) {
          if (data.length) {
            $scope.notify = data.length;
          }
        });

        refreshPendingFriends();

        $rootScope.$on('refreshFriends', refreshFriends.bind(this));
        $rootScope.$on('refreshFriends', refreshPendingFriends.bind(this));

        refreshFriends();
      }

      /**
       * Updates the users' logo for the ranking widget
       *
       * @param property
       */
      var updateRankingUserLogo = function(property) {
        if (this[property]) {
          if (gameSettings.settings.ranking.userLogo) {
            this[property].forEach(function(user) {
              user.rankingAvatarUrl = gameSettings.settings.ranking.userLogo;
            });
          } else {
            this[property].forEach(function(user) {
              user.rankingAvatarUrl = user.avatarUrl;
            });
          }
        }
      };

      $rootScope.$watch('friends', updateRankingUserLogo.bind($rootScope, 'friends'));
      $scope.$watch('pendingUsers', updateRankingUserLogo.bind($scope, 'pendingUsers'));
      $scope.$watch('usersCustomRanking', updateRankingUserLogo.bind($scope, 'usersCustomRanking'));
      $scope.$watch('usersGlobal', updateRankingUserLogo.bind($scope, 'usersGlobal'));
      $scope.$watch('all', updateRankingUserLogo.bind($scope, 'all'));
    }

    function refreshFriends() {

      if ($rootScope.currentUser !== undefined) {

        rankingService.requestFriends().then(function (data) {
          if (data.length == 0 && $scope.hasGlobalRanking) {
            $scope.toggleRanking('global');
          }

          $rootScope.currentUser.isCurrentUser = true;

          $rootScope.friends = data;
          $rootScope.friends.push($rootScope.currentUser);

          $rootScope.friends.sort(function (a, b) {
            return b['points'] - a['points'];
          })
        });
      }

    }

    function refreshPendingFriends() {
      rankingService.requestPendingFriends().then(function (data) {
        $scope.pendingUsers = data;
      });
    }

    $scope.delFriend = function (id) {
      $scope.openPopupDeleteFriend(id);
    };

    $scope.sendChallenge = function (id, name, image) {
      rankingService.sendChallenge(id,
        function() {
          angular.element(".selection img").attr("src", contentURL + "images/common/icons/tick.png");
          $rootScope.$broadcast('refreshFriends');

          $timeout(function () {
            $scope.toggleStopSearch();
          }, 1000)

        },
        function() {
          angular.element(".selection img").attr("src", contentURL + "images/common/icons/cross.png");

          $rootScope.showError('front.challenge.friendError');

          $timeout(function () {
            $scope.toggleStopSearch();
          }, 1000)
        }
      );
    };

    $scope.openNotifications = function () {
      if ($scope.notify) {
        $scope.notify = false;
        $scope.notificationsPopupAPI
          .setHeight(540)
          .open();
      }
    };

    $scope.hideActive = function () {
      findAnotherFriend();

      if (angular.element("#friends .selection:visible")) {
        angular.element("#friends .selection").slideUp();
        angular.element("#friends .boxSearch .search-area").slideDown();
      }

      if ($scope.searchText.length > 1) {
        angular.element(".boxSearch .scrollBox").css("opacity", "0.5");
        angular.element("#loadingSearch").fadeIn();

        if (searching != null) {
          $timeout.cancel(searching);
        }

        searching = $timeout(function () {
          angular.element("#loadingSearch").hide();
          angular.element(".boxSearch .scrollBox").css("opacity", "1");
          apiCall.get("api/1/friendship/search?text=" + $scope.searchText).then(function (response) {
            $scope.all = response.data;
          })
        }, 600);
      }
    };

    var findAnotherFriend = function() {
      $scope.searchFriend = $scope.findYourFriendLocale;
    };

    $scope.reto = function (user) {
      var element = user.id,
          image = user.rankingAvatarUrl,
          name = user.nameFront;

      $scope.searchFriend = $scope.findAnotherFriendLocale;
      angular.element(".selection img").attr("src", contentURL + image);
      $scope.challengedUserId = element;
      $scope.challengedUserName = name;
      $scope.challengedUserImage = image;
      angular.element("#friends .challenge-friend-question").html($scope.confirmChallengeQuestionLocale + "<br> " + name + "?");
      angular.element("#friends .boxSearch .search-area").slideUp();
      angular.element("#friends .selection").slideDown();

      angular.element('#friends .box-search-wrapper').addClass('searching');
    };

    $scope.toggleStartSearch = function() {
      angular.element('#friends .box-container-wrapper').slideToggle();
      angular.element('#friends .box-search-wrapper').slideToggle();
      findAnotherFriend();

      angular.element('#friends .box-search-wrapper input').focus();
    };

    $scope.toggleStopSearch = function() {
      if (angular.element('#friends .box-search-wrapper').hasClass('searching')) {
        angular.element("#friends .boxSearch .search-area").slideDown();
        angular.element("#friends .selection").slideUp();
        angular.element('#friends .box-search-wrapper').removeClass('searching');
      }

      $scope.searchText = '';
      $scope.all = [];
      angular.element('#friends .box-container-wrapper').slideToggle();
      angular.element('#friends .box-search-wrapper').slideToggle();
    };

    /**
     * Toggles the list of users of Friends or Global
     * @param element string value
     *  friends: For friends
     *  global: For Global
     *  custom: For custom
     */
    $scope.toggleRanking = function (element) {

      // Make sure the toggle only works after the carousel was initialized
      if ($scope.carouselAPI === undefined) {
        return;
      }

      if (angular.element('.ranking-selection .buttons .' + element + '-selector.active').length == 0) {

        var $carouselInfo = angular.element('.carousel.info');
        var $carouselContent = angular.element('.carousel.rankings');

        var $targetInfo = $carouselInfo.find('.slides > .stage > .' + element);
        var $targetContent = $carouselContent.find('.slides > .stage > .' + element);

        $scope.carouselAPI.moveToElement($carouselInfo, $targetInfo);
        $scope.carouselAPI.moveToElement($carouselContent, $targetContent);

        // Activate the new ranking link selector
        var activeElement = angular.element(".ranking-selection .buttons a.active");
        angular.element(".ranking-selection .buttons a." + element + "-selector").addClass("active");
        activeElement.removeClass("active");
      }
    };

    /**
     * Moves the ranking to the previous possible element
     */
    $scope.toggleRankingPrev = function() {
      var $element = angular.element('.ranking-selection .buttons .active').prev();

      if (!$element.length) {
        $element = angular.element('.ranking-selection .buttons a:last');
      }

      if ($element.length) {
        $scope.toggleRanking($element.data('carousel-selector'));
      }
    };

    /**
     * Moves the ranking to the next possible element
     */
    $scope.toggleRankingNext = function() {
      var $element = angular.element('.ranking-selection .buttons .active').next();

      if (!$element.length) {
        $element = angular.element('.ranking-selection .buttons a:first');
      }

      if ($element.length) {
        $scope.toggleRanking($element.data('carousel-selector'));
      }
    };

    function handleCarouselSwipe() {
      if (typeof Hammer !== 'undefined') {
        angular.element('.carousel .slides > .stage:not(.hammer-ready)').each(function () {
          var $stage = angular.element(this);

          $stage.addClass('hammer-ready');
          var h = new Hammer($stage.get(0));

          // Only allow horizontal pan
          h.get('swipe').set({direction: Hammer.DIRECTION_HORIZONTAL});

          h.on('swipeleft', function (ev) {
            $scope.toggleRankingNext();
          });

          h.on('swiperight', function (ev) {
            $scope.toggleRankingPrev();
          });
        });
      }
    }

    var carouselInit = function() {
      var CarouselAPI = $scope.carouselAPI = {

        getConfig: function($carousel) {
          return $carousel.data('carousel-config');
        },

        setConfig: function($carousel, config) {
          $carousel.data('carousel-config', config);
        },

        moveToElement: function($carousel, $element) {
          var position = $element.prevAll().length;

          if (position >= 0) {
            return CarouselAPI.moveTo($carousel, position);
          }

          return false;
        },

        moveNext: function($carousel) {
          var config = CarouselAPI.getConfig($carousel);
          return this.moveTo($carousel, config.currentPosition + 1);
        },

        movePrevious: function($carousel) {
          var config = CarouselAPI.getConfig($carousel);
          return this.moveTo($carousel, config.currentPosition - 1);
        },

        moveTo: function($carousel, position) {

          if (position < 0 || !$carousel) {
            return false;
          }

          var config = CarouselAPI.getConfig($carousel);

          if (config.$slides.length > 0) {

            var possiblePositions = config.$slides.length - 1;
            if (position >= 0 && position <= possiblePositions) {
              config.$stage.css({transform:  'translateX(-' + (config.width * position) + '%)'});

              angular.element(config.$slides[config.currentPosition]).removeClass('active');
              angular.element(config.$slides[position]).addClass('active');

              config.currentPosition = position;

              $carousel.trigger('carousel-move-to', config.$slides[position]);
              return true;
            }
          }

          return false;
        },

      };

      var updateCarousels = function() {

        angular.element('.carousel').each(function() {
          var $carousel = $(this);

          var $slidesContainer = $carousel.find('> .slides');

          var $stage = $slidesContainer.find('> .stage');

          var slideWidth = Utils.realWidth($slidesContainer);

          var $slides = $stage.find('> *');

          // We cannot make a carousel without slides
          if ($slides.length === 0) {
            return;
          }

          var config = {
            $slides: $slides,
            width: 100 / $slides.length, // This is a relative value, so it doesn't need an update on resize
            currentPosition: 0,
            $stage: $stage,
            marginSlide: 1,
          };

          // Set the scenario as big as the total elements aligned
          var stageWidth = 'calc(' + $slides.length + ' * 100%)';
          $stage.css({width: stageWidth});

          $slides.each(function(position) {

            // Needs to be recalculated when window gets resized
            var $slide = $(this)
              .css({'max-width': 'calc(' + config.width + '% - 1px)'}) // -1px is because the division might not be exact (have decimals)
              .addClass('carousel-item');

            // Determine which slide is active for the first time
            if ($slide.hasClass('active')) {
              config.firstSlidePosition = position;
            }

            CarouselAPI.setConfig($slide, {position: position});
          });

          if (config.firstSlidePosition === undefined) {
            config.firstSlidePosition = 0;
          }

          CarouselAPI.setConfig($carousel, config);

          CarouselAPI.moveTo($carousel, config.firstSlidePosition);
        });

      };

      updateCarousels();
      handleCarouselSwipe();

      // This is to support height calculations on other components
      $rootScope.$broadcast('windowResize');
    };

    init();

    // This will be executed in the next digest cycle, so the slider content will be rendered
    // This is because the slider gets constructed with information from the DOM
    $timeout(carouselInit, 0);
  }
]);
